/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Button, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Copy from 'copy-to-clipboard';

import { ReactComponent as SuccessIcon } from '@/assets/svg/icon_success.svg';
import './index.less';

const ContactCard = ({ contacts = {} }) => {
  const { description, phone, whatsApp, email, remarks, button, deeplink } = contacts;
  const { t } = useTranslation('contact');

  const [showModal, setShowModal] = useState(false);
  const [showCallModal, setShowCallModal] = useState(false);

  const onClick = () => {
    setShowModal(true);
  };

  const handleOk = () => {
    setShowModal(false);

    window.location.href = deeplink;
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const onCall = () => {
    onCopy(phone);
    // setShowCallModal(true);
  };

  const handleCall = () => {
    setShowCallModal(false);

    window.open(`tel:${phone}`);
  };

  const onCallCancel = () => {
    setShowCallModal(false);
  };

  const onCopy = (text) => {
    message.destroy();
    Copy(text);

    message.error({
      icon: <SuccessIcon className="tips-icon" />,
      className: 'toast-tips-content',
      getContainer: () => document.getElementById('root'),
      content: t('copied'),
      duration: 2,
    });
  };

  return (
    <div className="contact-card fade-in-item">
      <p className="contact-title">{description}</p>
      <span>
        <p className="contact-label">
          {t('phone')}
          {': '}
        </p>
        {/* {platform === 'Android' ? ( */}
        <a className="contact-phone" onClick={onCall}>
          {phone}
        </a>
        {/* ) : (
          <a className="contact-phone" onClick={() => onCopy(phone)}>
            {phone}
          </a>
        )} */}
      </span>
      <br></br>
      <span>
        <p className="contact-label">
          {t('email')}
          {': '}
        </p>
        <a className="contact-email" onClick={() => onCopy(email)}>
          {email}
        </a>
      </span>
      {
        whatsApp?.replaceAll(' ','')?<>
          <br></br>
          <span>
            <p className="contact-label">
              {t('whatsApp')}
              {': '}
            </p>
            <a href={`https://wa.me/852${whatsApp?.replaceAll(' ','')}`} className="contact-phone">
              +852 {whatsApp}
            </a>
          </span>
        </>:<></>
      }
      <br></br>
      <span className="contact-label">{remarks}</span>
      <Button className="contact-button" onClick={onClick}>
        <span className="contact-button-content">{button}</span>
      </Button>
      <Modal
        visible={showModal}
        width={'85.65%'}
        closable={false}
        centered={true}
        getContainer={() => document.getElementById('root')}
        onCancel={handleCancel}
        wrapClassName="contact-more-modal"
        maskClosable={true}
        footer={[
          <Button key="back" type="link" onClick={handleCancel}>
            {t('cancelText')}
          </Button>,
          <Button key="submit" type="link" onClick={handleOk}>
            {t('confirmText')}
          </Button>,
        ]}
      >
        <p>{t('contactConfirm')}</p>
      </Modal>
      <Modal
        visible={showCallModal}
        width={'85.65%'}
        closable={false}
        centered={true}
        getContainer={() => document.getElementById('root')}
        onCancel={onCallCancel}
        wrapClassName="contact-more-modal"
        maskClosable={true}
        footer={[
          <Button key="back" type="link" onClick={onCallCancel}>
            {t('cancelText')}
          </Button>,
          <Button key="submit" type="link" onClick={handleCall}>
            <span href={`tel:${phone}`}>{t('callText')}</span>
          </Button>,
        ]}
      >
        <p>{t('callConfirm')}</p>
      </Modal>
    </div>
  );
};

export default ContactCard;
