import React from 'react';
import { nanoid } from 'nanoid';

import './index.scss';

const Sunny = ({ time }) => {
  return (
    <div className="sunny">
      {time === 'night' ? (
        <>
          <div className="night-sky">
            <div className="stars">
              <div></div>
              <div></div>
              <div></div>
            </div>

            <div className="stars">
              <div></div>
              <div></div>
              <div></div>
            </div>

            <div className="stars">
              <div></div>
              <div></div>
              <div></div>
            </div>

            <div className="small-stars">
              {new Array(200).fill().map(() => (
                <div key={nanoid()} className="small-star"></div>
              ))}
            </div>
          </div>
          <div className="moon-container">
            <div className="moon">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
        </>
      ) : (
        <div className="sun-container">
          <div className="sun">
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sunny;
