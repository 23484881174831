import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, message, Image, Input } from 'antd';

import { useAuthContext } from '@/hooks/useAuth';
import { useProfileContext } from '@/hooks/useProfile';
import { resetPassword, resetEmailPassword } from '@/client/chabot';
import { ReactComponent as TipsIcon } from '@/assets/svg/icon_tips.svg';
import PopupLoadingImage from '@/assets/png/icon_popup_loading@2x.png';

import './index.less';

// type= account,tvbAccount,booking
const ForgetPasswordForm = ({ type = 'account', customer, onCancel, onFinish = () => {} }) => {
  const { t } = useTranslation('customer');
  const { t: tCommon } = useTranslation('common');
  const { authInfo = {}, clientToken } = useAuthContext();
  const { lang, userProfile } = useProfileContext();
  const tipInfo =
    type === 'tvbAccount' ? `@${customer.email.split('@')[1]}` : customer.mobile_number.slice(0, 2);

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [loading, setLoading] = useState(false);
  const [errorLabel, setErrorLabel] = useState({});
  const resetPIN = type === 'tvbAccount' ? resetEmailPassword : resetPassword;

  useEffect(() => {
    if (form.isFieldTouched(type === 'tvbAccount' ? 'email' : 'phone')) {
      setErrorLabel({});
      form.validateFields([type === 'tvbAccount' ? 'email' : 'phone'], { validateOnly: true }).then(
        () => {
          setSubmitDisabled(false);
        },
        () => {
          setSubmitDisabled(true);
        },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const submit = () => {
    setLoading(true);
    const { accessToken: token, mzToken } = authInfo;
    form.validateFields(Object.keys(values || {}), { validateOnly: true }).then(
      async () => {
        setSubmitDisabled(false);

        let result = '';
        const data =
          type === 'tvbAccount'
            ? {
                email: `${values.email}@${customer.email.split('@')[1]}`,
                for_register: false,
                locale: lang,
              }
            : {
                boss_id: userProfile?.boss_id,
                mobile_number: `${customer.mobile_number.slice(0, 2)}${values.phone}`,
              };
        try {
          const res = await resetPIN({ lang, token, clientToken, mzToken, data });
          console.log('resetPIN result', res);
          if (res.code === 0) {
            if (res.data.success) {
              result = 'message';
            } else {
              // email错误 - 预留其他错误
              let error = false;
              if (['20040002', '20040401'].includes(res.data.errors.code)) {
                setErrorLabel({
                  validateStatus: 'error',
                  help: t('label.error.emailIncorrect'),
                });
                error = true;
              }
              // 账号锁定wrong password 5 times.
              if (res.data.errors.code === '20000017') {
                result = 'tip';
                error = true;
              }
              // mobile number错误 - 预留其他错误
              else if (res.data.errors.code === '20040101') {
                setErrorLabel({
                  validateStatus: 'error',
                  help: t('label.error.phoneIncorrect'),
                });
                error = true;
              }

              if (!error) {
                setErrorLabel({
                  validateStatus: 'error',
                  help:
                    type === 'tvbAccount'
                      ? t('label.error.emailIncorrect')
                      : t('label.error.phoneIncorrect'),
                });
              }
            }
          } else {
            result = 'error';
          }
        } catch (err) {
          result = 'error';
        }

        setTimeout(() => {
          setLoading(false);
          if (!result) {
            return;
          }
          // 错误弹窗
          if (result === 'error') {
            return message.error({
              icon: <TipsIcon className="tips-icon" />,
              className: 'toast-tips-content',
              getContainer: () => document.getElementById('root'),
              content: tCommon('network.error'),
              duration: 2,
            });
          } else {
            onFinish(result || 'message', type);
          }
        }, 500);
      },
      () => {
        setSubmitDisabled(true);
      },
    );
  };

  return (
    <div className="reset-password-form change-password-form">
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        initialValues={{}}
        onFinish={submit}
        autoComplete="off"
      >
        <div className="pwd-modal-title">
          {(type !== 'account' ? `${t('account.tvbAccount')} - ` : '') +
            t(`label.${type}.forgetPassword`)}
        </div>
        <div className="pwd-modal-description">{t(`label.${type}.forgetPasswordPrefix`)}</div>
        {type === 'tvbAccount' ? (
          <Form.Item
            name="email"
            validateFirst={true}
            className="email-input-item"
            validateTrigger={['onBlur']}
            rules={[
              {
                required: true,
                message: t('label.error.emailIncorrect'),
                validator: (_, value) => {
                  if (value) {
                    form.setFieldsValue({ ...values, email: value.trim() });
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
              },
            ]}
            {...errorLabel}
          >
            <Input placeholder={t('label.tvbAccount.forgetPasswordInput')} addonAfter={tipInfo} />
          </Form.Item>
        ) : (
          <Form.Item
            name="phone"
            validateFirst={true}
            validateTrigger={['onBlur', 'onChange']}
            rules={[
              {
                required: true,
                min: 1,
                pattern: /^\d+$/,
                message: t('label.error.phoneIncorrect'),
              },
            ]}
            {...errorLabel}
            style={{ '--before-content': `"+${customer.area_code || 852}"` }}
          >
            <Input className="forget-input" type="tel" addonBefore={tipInfo} maxLength={11} />
          </Form.Item>
        )}
        <div className="pwd-format-label">{t(`label.${type}.forgetPasswordLabel`)}</div>
        <Form.Item className="pwd-submit-btn-item">
          <Button
            className={`pwd-submit-btn ${submitDisabled ? 'pwd-submit-btn-disbaled' : ''}`}
            disabled={submitDisabled}
            type="primary"
            htmlType="submit"
          >
            {loading ? (
              <Image className="loading-icon" src={PopupLoadingImage} preview={false} async />
            ) : (
              t('button.confirm')
            )}
          </Button>
        </Form.Item>
        <div className="pwd-format-label pwd-forget-btn-label">
          <Button type="link" className="pwd-forget-btn" onClick={onCancel}>
            {t('button.cancel')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ForgetPasswordForm;
