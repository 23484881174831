// @flow strict

import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import config from '@/config';

import { zhHK } from './locales/zh-HK';
import { enUS } from './locales/en-US';

const resources = {
  'zh-HK': zhHK,
  'en-US': enUS,
};

i18next
  .use(initReactI18next)
  .use(detector)
  .init({
    ...config.i18n,
    resources,
  });

export default i18next
