export const common = {
  now: 'now',
  yesterday: 'Yesterday',
  weeks: {
    sun: 'Sun',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
  },
  showMore: 'Show More',
  connecting: 'Loading...please wait',
  connectedFailed: 'Connected failed',
  notConnect: 'No connection. Refresh?',
  refresh: 'Refresh',
  send: 'Send',
  notLoginLabel: 'Found you are offLine, go to login now?',
  login: 'Login Now',
  network: {
    error: 'Network Offline',
    offline: 'Network Disconnected',
  },
  historyFailed: 'Network failed, please try again.',
  exit: {
    content: 'Do you want to leave this page?',
    confirm: 'Leave',
    cancel: 'Stay',
  },
};
