/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, message, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Copy from 'copy-to-clipboard';

import TitleCard from '@/components/titleCard';
import { useProfileContext } from '@/hooks/useProfile';
import ProfileItem from './profileItem';
import ChangePasswordForm from './changePasswordForm';
import ForgetPasswordForm from './forgetPasswordForm';
import { ReactComponent as SuccessIcon } from '@/assets/svg/icon_success.svg';
import { ReactComponent as ModalCloseIcon } from '@/assets/svg/icon_popup_close.svg';
import { ReactComponent as PopupMessageIcon } from '@/assets/svg/icon_popup_message.svg';
import { ReactComponent as PopupTipIcon } from '@/assets/svg/icon_popup_tip.svg';
import { ReactComponent as PopupSuccessIcon } from '@/assets/svg/icon_popup_success.svg';
import './index.less';

const CustomerProfile = ({ locker, title, customer = {}, lockCustomer = () => {} }) => {
  const { t } = useTranslation('customer');
  const { lang } = useProfileContext();
  const [showModal, setShowModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [result, setResult] = useState();
  const [type, setType] = useState('booking');
  const [locked, setLocked] = useState(locker.login);
  const [bookingLocked, setBookingLocked] = useState(locker.booking);

  const RESULT_TYPE = {
    message: { icon: <PopupMessageIcon />, label: 'forgetPasswordSuccess' },
    tip: { icon: <PopupTipIcon />, label: 'label.error.limitFiveError' },
    success: { icon: <PopupSuccessIcon />, label: 'passwordSuccess' },
  };

  const PROFILE_FIELDS = customer.login_email_verified
    ? {
        name: lang === 'en' ? 'last_name' : 'chinese_name',
        tvbAccountEmail: 'email',
        phone: 'mobile_number',
      }
    : {
        bossId: 'boss_id',
        name: lang === 'en' ? 'last_name' : 'chinese_name',
        email: 'email',
        phone: 'mobile_number',
      };

  const openModal = (type) => {
    // 訂購密碼或賬號密碼不能處於locked
    if ((type === 'booking' && !bookingLocked) || (type !== 'booking' && !locked)) {
      setType(type);
      setShowModal(true);
    } else {
      setResult('tip');
      handleResultOpen();
    }
  };

  const cancelModal = () => {
    if (forgetPassword) {
      setForgetPassword(false);
    } else {
      setShowModal(false);
      setType(null);
    }
  };

  const onForgetPassword = () => {
    setForgetPassword(true);
  };

  const onCancelForgetPassword = () => {
    setForgetPassword(false);
  };

  const onFinish = (result, type) => {
    setResult(result);
    if (result === 'tip') {
      // 訂購密碼
      if (type === 'booking') {
        lockCustomer({ booking: true });
        setBookingLocked(true);
      } else {
        lockCustomer({ login: true });
        setLocked(true);
      }
    }
    setShowModal(false);
    setForgetPassword(false);
    handleResultOpen();
  };

  const handleResultOpen = () => {
    setShowResultModal(true);
  };

  const handleResultCancel = () => {
    setShowResultModal(false);
    setType(null);
    setResult(null);
    setForgetPassword(false);
  };

  const onCopy = (text) => {
    message.destroy();
    Copy(text);

    message.error({
      icon: <SuccessIcon className="tips-icon" />,
      className: 'toast-tips-content',
      getContainer: () => document.getElementById('root'),
      content: t('copied'),
      duration: 2,
    });
  };

  return (
    <div className="profile-card-wrapper fade-in-item">
      <TitleCard title={title} />
      <div className="profile-card">
        <div className="profile-card-body">
          {Object.keys(PROFILE_FIELDS).map((key, index) => {
            let field = PROFILE_FIELDS[key];
            let value = customer[field];
            if (key === 'name' && (!customer[PROFILE_FIELDS[key]] || lang === 'en')) {
              value = `${customer.first_name && `${customer.first_name}, `}${customer.last_name}`;
            }
            if (key === 'phone' && customer.area_code) {
              value = `+${customer.area_code} ${value}`;
            }
            return (
              <ProfileItem key={`profiel-${index}`} label={t(`account.${key}`)} value={value} />
            );
          })}
        </div>
        <div className="profile-card-footer">
          <Button
            className="profile-change-btn"
            onClick={() => openModal(customer.login_email_verified ? 'tvbAccount' : 'account')}
          >
            {t('button.changePassword')}
          </Button>
          {customer.login_email_verified ? (
            <Button className="profile-change-btn" onClick={() => openModal('booking')}>
              {t('button.changeBookingPassword')}
            </Button>
          ) : null}
        </div>
      </div>
      <Modal
        visible={showModal}
        width={'85.65%'}
        destroyOnClose
        closable={true}
        centered={true}
        getContainer={() => document.getElementById('root')}
        onCancel={cancelModal}
        wrapClassName="pwd-modal profile-pwd-modal"
        maskClosable={false}
        keyboard={false}
        footer={null}
        closeIcon={<ModalCloseIcon />}
      >
        {forgetPassword ? (
          <ForgetPasswordForm
            type={type}
            customer={customer}
            onCancel={onCancelForgetPassword}
            onFinish={onFinish}
          />
        ) : (
          <ChangePasswordForm
            type={type}
            customer={customer}
            onForgetPassword={onForgetPassword}
            onFinish={onFinish}
          />
        )}
      </Modal>
      <Modal
        visible={showResultModal && result}
        width={'85.65%'}
        closable={false}
        centered={true}
        getContainer={() => document.getElementById('root')}
        onCancel={handleResultCancel}
        wrapClassName="pwd-modal pwd-result-modal"
        maskClosable={true}
        footer={[
          <Button key="submit" type="link" onClick={handleResultCancel}>
            {result === 'tip' ? t(`button.close`) : t(`button.confirm`)}
          </Button>,
        ]}
      >
        <div className="result-modal-body">
          <div className="result-modal-body-icon">{RESULT_TYPE[result]?.icon}</div>
          <div className="result-modal-label">
            <p>
              {result === 'tip'
                ? t(RESULT_TYPE[result]?.label)
                : t(`label.${type}.${RESULT_TYPE[result]?.label}`)}
            </p>
            {result === 'tip' ? (
              <p>
                <span>{t('label.error.contactLabel')}</span>
                <a onClick={onCopy}>{t('label.error.contactPhone')}</a>
                <span>{t('label.error.contactLabelLater')}</span>
              </p>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CustomerProfile;
