import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import TitleCard from '@/components/titleCard';
import { useProfileContext } from '@/hooks/useProfile';
import { ReactComponent as DoorArrowIcon } from '@/assets/svg/icon_door_single_arrow.svg';
import ProgrammeItem from './programmeItem';

import './index.less';

/**
 *
 * @param {*} programme
 *    id: string
 *    title: string
 *    image: Url
 *    start_time: string
 *    end_time: string
 *    description: string
 *
 * @returns
 */
const ProgrammeListCard = ({
  programmeList = {},
  showMoreProgrmme = () => {},
  handleMoreTags,
  showAllTags,
}) => {
  const { t } = useTranslation('programme');
  const { title_en, title_tc, items } = programmeList;
  const { lang } = useProfileContext();

  const handleMoreTagsClick = (index) => {
    handleMoreTags(index);
  };

  return (
    <div className="programme-list-card">
      <TitleCard title={lang === 'en' ? title_en : title_tc} />
      <div className="programme-list">
        {items.slice(0, 3).map((programme, index) => (
          <ProgrammeItem
            key={index}
            programme={programme}
            handleMoreTags={() => handleMoreTagsClick(index)}
            showAllTags={showAllTags}
          />
        ))}
        <Button className="programme-list-button" onClick={() => showMoreProgrmme(programmeList)}>
          <span>
            <span>{t('more')}</span>
            <DoorArrowIcon />
          </span>
        </Button>
      </div>
    </div>
  );
};

export default ProgrammeListCard;
