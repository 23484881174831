import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import * as R from 'ramda';
// import 'swiper/swiper.less'; // core Swiper
// import 'swiper/modules/pagination/pagination.less'; // Pagination module

import TitleCard from '@/components/titleCard';
import 'swiper/scss';
import 'swiper/scss/pagination';

import './index.less';

SwiperCore.use([Pagination]);

const SwiperContainer = ({
  title,
  items,
  pagination = false,
  slideClassName,
  onSlideChange = () => {},
}) => {
  const loadSwiper = useRef(null);

  if (R.isEmpty(items)) {
    return null;
  }

  return (
    <div className="swiper-container fade-in-item">
      <TitleCard title={title} />
      <div ref={loadSwiper}>
        <Swiper
          key={`swiperBanner`}
          slidesPerView={'auto'}
          slidesPerGroup={1}
          pagination={pagination}
          loop={false}
          simulateTouch={true}
          className={`swiper-body ${pagination ? 'contain-pagination-swiper' : ''}`}
          resistanceRatio={0}
          slidesOffsetBefore={pagination ? 0 : 8}
          slidesOffsetAfter={8}
          speed={500}
          freeMode={true}
          preloadImages={false}
          onSlideChange={onSlideChange}
        >
          {items.map((item, index) => {
            return (
              <SwiperSlide key={index} className={slideClassName}>
                {item}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default SwiperContainer;
