import React from 'react';
import { Button, List } from 'antd';

import SwiperContainer from '@/components/swiperContainer';
import { ReactComponent as IconArrowSvg } from '@/assets/svg/icon_arrow.svg';

import './index.less';

const HorizontalRecommend = ({ recommends, onClick }) => {
  return (
    <SwiperContainer
      slideClassName={'recommend-swiper-slide-item'}
      items={recommends.map((word, index) => (
        <Button
          key={index}
          className="recommend-button"
          onClick={() => {
            onClick(word);
          }}
        >
          {word}
        </Button>
      ))}
    />
  );
};

const VerticalRecommend = ({ recommends, onClick }) => {
  return (
    <div className={'vertical-recommend fade-in-item'}>
      <List
        dataSource={recommends}
        renderItem={(word, index) => (
          <List.Item
            key={index}
            className="vertical-recommend-item"
            onClick={() => {
              onClick(word);
            }}
          >
            <span>{word}</span>
            <IconArrowSvg className="direct-icon" />
          </List.Item>
        )}
      />
    </div>
  );
};

// mode: 4=横向horizontal default，5=纵向vertical
const Recommend = ({ recommend = '', mode, onRecommendClick }) => {
  if (!recommend) {
    return null;
  }

  const recommends = recommend.split(',');

  return mode === 5 ? (
    <VerticalRecommend recommends={recommends} onClick={onRecommendClick} />
  ) : (
    <HorizontalRecommend recommends={recommends} onClick={onRecommendClick} />
  );
};

export default Recommend;
