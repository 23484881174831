import React, { forwardRef, useState } from 'react';
import videojs from 'video.js';
// import 'videojs-youtube';
import { Image } from 'antd';

import { ReactComponent as VideoPlayIcon } from '@/assets/svg/icon_play.svg';

// import 'video.js/dist/video-js.css';
import './videojs.css';
import './index.less';

const VideoPlayer = forwardRef((props, ref) => {
  const {
    className = '',
    url = '',
    poster,
    playsinline = false,
    pauseReload = false,
    silkyPlay = false, // 退出全屏时是否继续播放
  } = props;

  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const fullScreen = React.useRef(false);
  const playing = React.useRef(false);
  const [imageStatus, setImageStatus] = useState(true);

  React.useEffect(() => {
    const handlePlayerReady = (player) => {
      playerRef.current = player;

      player.on('play', () => {
        playing.current = true;
        const audios = document.getElementsByTagName('audio');
        if (audios && audios.length > 1) {
          for (let index = 0; index < audios.length; index++) {
            const instance = audios[index];
            instance.pause();
          }
        }

        const videos = document.getElementsByTagName('video');
        if (videos && videos.length > 1) {
          for (let index = 0; index < videos.length; index++) {
            const instance = videos[index];
            if (instance !== videoRef.current) {
              instance.pause();
            }
          }
        }

        // if (!playsinline) {
        //   player.requestFullscreen();
        // }
      });

      player.on('pause', () => {
        console.log('playing pause...');
        playing.current = false;
      });

      player.on('playing', () => {
        console.log('playing...');
        playing.current = false;
      });

      player.on('loadeddata', () => {
        if (playing.current && !playsinline) {
          player.requestFullscreen();
        }
      });

      player.on('ended', () => {
        console.log('playing end...');
        videoRef.current.src = '';
        videoRef.current.src = url;

        if (player.isFullscreen()) {
          player.exitFullscreen();
        }
      });

      player.on('fullscreenchange', (e) => {
        if (!silkyPlay && fullScreen.current && videoRef.current) {
          console.log('Exit full screen to pause');
          videoRef.current.pause();

          if (pauseReload) {
            videoRef.current.src = '';
            videoRef.current.src = url;
          }
        }
        fullScreen.current = !fullScreen.current;
      });

      player.on('error', (error) => {
        playing.current = false;
        if (player.isFullscreen()) {
          player.exitFullscreen();
        }

        console.log('playing error', error);
      });
    };

    const getSourceType = () => {
      // if (url.indexOf('youtube.com') > -1) {
      //   return 'video/youtube';
      // }

      // if (url.indexOf('youtu.be') > -1) {
      //   return 'video/youtube';
      // }
      return 'video/mp4';
    };

    const playerOption = {
      // poster,
      autoplay: false,
      controls: true,
      responsive: true,
      bigPlayButton: true,
      fluid: true,
      playsinline: playsinline,
      'webkit-playsinline': playsinline,
      // crossOrigin: 'anonymous',
      // cors: 'anonymous',
      preload: 'none',
      'data-setup': {},
      withCredentials: true,
      html5: {
        vhs: {
          withCredentials: true,
          'webkit-playsinline': playsinline,
          playsinline: playsinline,
          'x-webkit-airplay': true,
          'x5-video-player-type': 'h5',
          'x5-video-player-fullscreen': true,
          'x5-video-ignore-metadata': true,
        },
      },
      sources: [
        {
          src: url,
          type: getSourceType(),
        },
      ],
      techOrder: ['html5'],
      // techOrder: getSourceType() === 'video/youtube' ? ['youtube'] : ['html5', 'youtube'],
      controlBar: {
        children: [
          'progressControl',
          'playToggle',
          'volumePanel',
          'currentTimeDisplay',
          'timeDivider',
          'durationDisplay',
          // 'remainingTimeDisplay',
          'fullscreenToggle',
        ],
      },
    };

    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const playerInstance = videojs(videoElement, playerOption, function () {
        // TODO: refactor it
        try {
          this.volume(0.5);
          // eslint-disable-next-line no-empty
        } catch (error) {}

        handlePlayerReady(playerInstance);
      });

      playerRef.current = playerInstance;
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poster, url, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className={`video-player ${className}`}>
      <div data-vjs-player>
        {poster && imageStatus ? (
          <div className={`vjs-poster self-poster`} tabIndex="-1" aria-disabled="false">
            <div className="image-background" style={{ backgroundImage: `url(${poster})` }}>
              <div />
            </div>
            <Image
              className="poster-image"
              preview={false}
              src={poster}
              onError={() => {
                setImageStatus(false);
              }}
            />
            <div className="poster-mask"></div>
          </div>
        ) : null}
        <button
          className="vjs-big-play-button"
          type="button"
          title="Play Video"
          aria-disabled="false"
        >
          <VideoPlayIcon />
        </button>
        <video ref={videoRef} className={`video-js vjs-big-play-centered video-body`} />
      </div>
    </div>
  );
});

export default VideoPlayer;
