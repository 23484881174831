import getClient from '../utils/clientUtil';
import { getPlatformHeader } from '@/utils/platform';
import config from '@/config';
import base64 from 'base-64';

const { retryAxios, singleAxios } = getClient({ baseURL: config.api });

export const getToken = ({ bossId }) => retryAxios.get('/base/login', { params: { bossId } });

export const getBaseInfo = ({ token, clientToken, mzToken, lang }) =>
  retryAxios.get('/system-info', {
    headers: {
      Authorization: token,
      'X-Client-Token': clientToken,
      'X-App-Client': getPlatformHeader({ lang }),
    },
    params: {
      mz_token: mzToken,
    },
  });

export const getHistory = ({ lang, token, startId = 0, limit = 10 }) =>
  singleAxios.get(`/message/history?start_id=${startId}&limit=${limit}`, {
    headers: {
      Authorization: token,
      'X-App-Client': getPlatformHeader({ lang }),
    },
  });

export const clearNotification = ({ lang, token, badgeInfo = {} }) =>
  retryAxios.post('/badge/set-read', badgeInfo, {
    headers: {
      Authorization: token,
      'X-App-Client': getPlatformHeader({ lang }),
    },
  });

export const getProgrammeLabels = ({ lang, token, platform = 'web' }) =>
  retryAxios.get(`/programme/labels?platform=${platform}`, {
    headers: {
      Authorization: token,
      'X-App-Client': getPlatformHeader({ lang }),
    },
  });

export const getSystemConfig = () => retryAxios.get('/config');

export const sendException = async ({ lang, token, errorInfo, errorType = 'error' }) => {
  const { userInfo, appInfo, deviceInfo, error } = errorInfo;
  let isNormalToken = false;
  const jwtArr = String(token).split('.');
  if (jwtArr.length >= 2) {
    let payload = JSON.parse(base64.decode(jwtArr[1]));
    if (payload?.boss_id && payload?.extra?.profile_id) {
      isNormalToken = true;
    }
  }

  await retryAxios.post(
    `/exception`,
    {
      url: window?.location?.href,
      app_info: { appInfo, userInfo, token: isNormalToken ? 'normal' : token },
      device_info: deviceInfo,
      error_type: errorType,
      error: error,
    },
    {
      headers: {
        Authorization: token,
        'X-App-Client': getPlatformHeader({ lang }),
      },
    },
  );
};

export const changePassword = ({ token, clientToken, mzToken, lang, data }) =>
  retryAxios.post(
    '/customer/password/change',
    { ...data, mz_token: mzToken },
    {
      headers: {
        Authorization: token,
        'X-Client-Token': clientToken,
        'X-App-Client': getPlatformHeader({ lang }),
      },
    },
  );

export const changeEmailPassword = ({ token, clientToken, mzToken, lang, data }) =>
  retryAxios.post(
    '/customer/email-password/change',
    { ...data, mz_token: mzToken },
    {
      headers: {
        Authorization: token,
        'X-Client-Token': clientToken,
        'X-App-Client': getPlatformHeader({ lang }),
      },
    },
  );

export const resetPassword = ({ token, clientToken, mzToken, lang, data }) =>
  retryAxios.post(
    '/customer/password/reset',
    { ...data, mz_token: mzToken },
    {
      headers: {
        Authorization: token,
        'X-Client-Token': clientToken,
        'X-App-Client': getPlatformHeader({ lang }),
      },
    },
  );

export const resetEmailPassword = ({ token, clientToken, mzToken, lang, data }) =>
  retryAxios.post(
    '/customer/email-password/reset',
    { ...data, mz_token: mzToken },
    {
      headers: {
        Authorization: token,
        'X-Client-Token': clientToken,
        'X-App-Client': getPlatformHeader({ lang }),
      },
    },
  );

export const validatePassword = ({ token, clientToken, mzToken, lang, data }) =>
  retryAxios.post(
    '/customer/validate_pin',
    { ...data, mz_token: mzToken },
    {
      headers: {
        Authorization: token,
        'X-Client-Token': clientToken,
        'X-App-Client': getPlatformHeader({ lang }),
      },
    },
  );
