import moment from 'moment';

const timeFormat = 'HH:mm';
const dateFormat = 'YYYY-MM-DD HH:mm';

export const formatNowTime = (time, t) => {
  const dateTime = moment(time);

  const yesterday = t('yesterday');
  const weeks = [
    t('weeks.sun'),
    t('weeks.mon'),
    t('weeks.tue'),
    t('weeks.wed'),
    t('weeks.thu'),
    t('weeks.fri'),
    t('weeks.sat'),
  ];

  const show = dateTime.calendar(null, {
    sameDay: timeFormat,
    lastDay: `[${yesterday}] ${dateTime.format(timeFormat)}`,
    lastWeek: `[${weeks[dateTime.day()]}] ${dateTime.format(timeFormat)}`,
    sameElse: dateFormat,
  });

  return show;
};

export const getTimeInNeed = (time = new Date(), { last, t }) => {
  if (last) {
    const diff = moment(time).diff(moment(last), 'seconds');

    if (diff < 5 * 60) {
      return null;
    }
  }

  return formatNowTime(time, t);
};

export const getDayOrNight = () => {
  const nowHour = moment().hour();
  // 6 am - 6 pm => day
  return nowHour > 6 && nowHour < 18 ? 'day' : 'night';
};
