import React from 'react';

import AudioPlayer from '@/components/audioPlayer';
import './index.less';

const Text = ({ words, audio }) => {
  if (!words && !audio) {
    return null;
  }

  return (
    <div className="text-module fade-in-item">
      {words && (
        <div className={`text-content ${audio ? '' : 'text-content-clear'}`}>
          <p>{words}</p>
        </div>
      )}
      {words && audio && <AudioPlayer className="audio-button" audioUrl={audio} />}
    </div>
  );
};

export default Text;
