import React, { useEffect, useRef, useState } from 'react';

import { getDayOrNight } from '@/utils/dateTimeUtil';
import Sunny from './sunny';
import './index.less';

import WeatherLeafImage from '@/assets/png/weather_leaf.png';

import WeatherCloudImage from '@/assets/png/weather_cloud.png';

// time= day,night,sunrise,sunset
const getCondition = (weather) => {
  const time = getDayOrNight();

  console.log({ weather, time });

  const conds = {
    clouds: {
      condition: {
        clouds: true,
      },
      time,
    },
    lightning: {
      condition: {
        lightning: true,
        rain: true,
      },
      time: 'night',
    },
    rain: {
      condition: {
        rain: true,
      },
      time,
    },
    sunny: {
      condition: {
        sunny: true,
      },
      time,
    },
    wind: {
      condition: {
        wind: true,
      },
      time,
    },
  };

  return conds[weather] || conds.sunny;
};

const WeatherCard = ({ docId: id, weather }) => {
  const context = useRef({});
  const assets = useRef([]);
  const timers = useRef({});
  const canvas = useRef({});
  const conditions = useRef({});
  const animationId = useRef(false);
  const [imageAssetsLoaded, setImageAssetsLoaded] = useState(false);
  const [spawnedClouds, setSpawnedClouds] = useState(false);
  const [init, setInit] = useState(false);

  const time = getDayOrNight();
  const windSpeed = 30;
  const rainColor = 'rgba(255, 255, 255, .4)';
  const imageAssets = {
    leaf: {
      src: WeatherLeafImage,
    },
    cloud: {
      src: WeatherCloudImage,
      width: 1792,
      height: 276,
    },
  };

  useEffect(() => {
    const initWeather = async () => {
      // Initialise
      const inputs = document.getElementsByTagName('input');
      const canvasDoc = document.getElementById('canvas');

      if (!canvasDoc) {
        return;
      }

      context.current = canvasDoc.getContext('2d');
      canvas.current = canvasDoc;

      for (var i = 0, n = inputs.length; i < n; i++) {
        if (inputs[i].type === 'text') {
          inputs[i].addEventListener('keyup', updateConditions);
        } else {
          inputs[i].addEventListener('change', updateConditions);
        }
      }

      preLoadImageAssets(function () {
        setConditionReady();
      });

      setInit(true);
    };

    if (!init) {
      initWeather();

      conditions.current = getCondition(weather);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets.current]);

  // Util methods
  const randomRange = function (min, max, round) {
    const roundInstance = round === undefined ? true : false;
    const val = Math.random() * (max - min) + min;
    return roundInstance ? Math.floor(val) : val;
  };

  const preLoadImageAssets = function (callback) {
    let imageAssetsCount = 0;
    let imageAssetsLoadedCount = 0;

    if (imageAssetsLoaded) {
      if (callback) {
        callback();
      }
      return;
    }

    const loadedHandler = function () {
      imageAssetsLoadedCount++;
      if (imageAssetsLoadedCount === imageAssetsCount) {
        setImageAssetsLoaded(true);
        if (callback) {
          callback();
        }
      }
    };

    for (const imageAssetName in imageAssets) {
      const imageAsset = imageAssets[imageAssetName];
      imageAssetsCount++;
      imageAsset.image = new Image();
      imageAsset.image.onload = loadedHandler;
      imageAsset.image.src = imageAsset.src;
    }
  };

  // Weather control methods
  const updateConditions = function (event) {
    const input = event.target;
    const name = input.name;
    const type = input.type;

    if (type === 'radio') {
      if (name === 'time_of_day') {
        canvas.current.className = input.value;
      }
    }

    if (type === 'checkbox') {
      conditions.current.condition[name] = input.checked;
      setConditionReady();
    }
  };

  const setConditionReady = function () {
    // stop spawning
    pause();

    // clear flags
    setSpawnedClouds(false);

    // clear assets
    for (let i = 0, n = assets.current.length; i < n; i++) {
      assets.current.splice(i, 1);
      n--;
      i--;
    }

    // start spawning
    beginSpawning();
  };

  //Spawning timers
  const beginSpawning = function () {
    if (animationId.current) {
      return;
    }

    // SPAWN NEW DROPS CONSISTENTLY
    if (conditions.current.condition.sunny) {
      assets.current = [...assets.current];

      setSpawnedClouds(true);
    }

    // SPAWN BUNCH OF CLOUDS
    if (conditions.current.condition.clouds && !spawnedClouds) {
      assets.current = [
        ...assets.current,
        new cloud({ x: -1100 }),
        new cloud({ x: -1000 }),
        new cloud({ x: -900 }),
        new cloud({ x: -700 }),
        new cloud({ x: -600 }),
        new cloud({ x: -400 }),
        new cloud({ x: -100 }),
        new cloud({ x: 200 }),
        new cloud({ x: 400 }),
        new cloud({ x: 700 }),
        new cloud({ x: 900 }),
        new cloud({ x: 1200 }),
        new cloud({ x: 1400 }),
      ];

      setSpawnedClouds(true);
    }

    // SPAWN NEW DROPS CONSISTENTLY
    if (conditions.current.condition.rain) {
      const rain = setInterval(function () {
        assets.current = [...assets.current, new rainDrop()];
      }, 60);

      timers.current = {
        ...timers.current,
        rain,
      };
    }

    // SPAWN NEW FLAKES CONSISTENTLY
    if (conditions.current.condition.snow) {
      const snow = setInterval(function () {
        assets.current = [...assets.current, new snowFlake()];
      }, 250);

      timers.current = {
        ...timers.current,
        snow,
      };
    }

    // SPORADICALLY SPAWN RANDOM BUNCH OF LEAVES
    if (conditions.current.condition.wind) {
      const spawnLeaves = function () {
        let assetsArray = [];
        for (let i = 0, n = randomRange(0, 10); i < n; i++) {
          assetsArray.push(new blowingLeaf());
        }

        assets.current = [...assets.current, ...assetsArray];
        timers.current = {
          ...timers.current,
          wind: setTimeout(spawnLeaves, randomRange(500, 1500)),
        };
      };

      assets.current = [
        ...assets.current,
        // new cloud({ y: -700 }),
        // new cloud({ y: -700 }),
        // new cloud({ y: -700 }),
        // new cloud({ y: -700 }),
        // new cloud({ x: 120 }),
        // new cloud({ x: 100 }),
      ];

      setSpawnedClouds(true);
      spawnLeaves();
    }

    // SPORADICALLY SPAWN LIGHTNING
    if (conditions.current.condition.lightning) {
      const spawnLightning = function () {
        const rand = randomRange(0, 10);
        if (rand > 5) {
          timers.current = {
            ...timers.current,
            secondFlash: setTimeout(function () {
              assets.current = [...assets.current, new lightning()];
            }, 200),
          };
        }
        assets.current = [...assets.current, new lightning()];
        timers.current = {
          ...timers.current,
          lightning: setTimeout(spawnLightning, randomRange(500, 3000)),
        };
      };

      spawnLightning();
    }

    animate();
  };

  // Animation methods
  const pause = function () {
    cancelAnimationFrame(animationId.current);
    animationId.current = false;

    for (const intervalId in timers.current) {
      if (timers.current[intervalId]) {
        clearInterval(timers.current[intervalId]);
        clearTimeout(timers.current[intervalId]);
      }
    }
  };

  const animate = function () {
    // clear
    context.current.clearRect(0, 0, canvas.current.width, canvas.current.height);

    // draw each asset, if false, remove particle from assets
    for (let i = 0, n = assets.current.length; i < n; i++) {
      if (!assets.current[i].draw()) {
        assets.current.splice(i, 1);
        n--;
        i--;
      }
    }

    // continue
    animationId.current = window.requestAnimationFrame(animate);
  };

  // Cloud particle
  const cloud = function (options) {
    this.type = 'cloud';
    this.img = options.img || imageAssets.cloud;

    this.width = this.img.width; //randomRange(200, 500);
    this.height = this.img.height; //50;

    const max = 5;
    this.xVelocity = (windSpeed - randomRange(0, max)) / 150;
    this.yVelocity = 0;

    this.x = (options.x || randomRange(-100, canvas.current.width + 100)) + randomRange(-200, 200);
    this.y = options.y || randomRange(0 - this.height / 2, -150) - 7;
  };

  cloud.prototype.draw = function () {
    this.x += this.xVelocity;
    // context.current.scale(1, -1);

    context.current.drawImage(
      this.img.image,
      10,
      0,
      this.img.width,
      this.img.height,
      this.x,
      this.y,
      this.img.width,
      this.img.height,
    );

    // restore context
    context.current.restore();

    if (this.xVelocity > 0) {
      // >>>
      if (this.x > canvas.current.width) {
        this.xVelocity = (windSpeed - randomRange(0, 10)) / 60;
        this.x = 0 - this.width;
      }
    } else {
      // <<<
      if (this.x < 0 - this.width) {
        this.xVelocity = (windSpeed - randomRange(0, 10)) / 120;
        this.x = canvas.current.width;
      }
    }

    return true;
  };

  // Rain drop particle
  const rainDrop = function () {
    this.type = 'rain_drop';
    this.width = 1;
    this.height = randomRange(15, 25);

    this.x = randomRange(0, canvas.current.width);
    this.y = -10;

    this.xVelocity = 0;
    this.yVelocity = 8;
  };

  rainDrop.prototype.draw = function () {
    this.y += this.yVelocity;
    context.current.fillStyle = rainColor;
    context.current.fillRect(this.x, this.y, this.width, this.height);

    if (this.y > canvas.current.height) {
      // occasionally, make a splash!
      if (Math.floor(Math.random() * 10) > 7) {
        for (let i = 0, n = randomRange(3, 5); i < n; i++) {
          assets.current = [...assets.current, new splashDrop(this.x)];
        }
      }
      return false;
    }

    return true;
  };

  // Splash drop particla
  const splashDrop = function (x) {
    this.type = 'splash_drop';
    this.width = 2;
    this.height = 2;

    this.x = x;
    this.y = canvas.current.height;

    this.yVelocity = randomRange(-1, -3, false);
    this.xVelocity = randomRange(-2, 2, false);

    this.age = 0;
    this.maxAge = 30;
  };

  splashDrop.prototype.draw = function () {
    this.y += this.yVelocity;
    this.x += this.xVelocity;

    context.current.fillStyle = rainColor;
    context.current.fillRect(this.x, this.y, this.width, this.height);

    this.yVelocity += 0.1;

    this.age++;
    if (this.age > this.maxAge) {
      return false;
    }

    return true;
  };

  // Snow flake particle
  const snowFlake = function () {
    this.type = 'snow_flake';
    this.width = randomRange(10, 30);
    this.height = this.width;

    this.x = randomRange(-200, canvas.current.width + 200);
    this.y = -30;

    this.xVelocity = (windSpeed - randomRange(0, 10)) / 60;
    this.yVelocity = randomRange(0.8, 1.4, false);

    this.opacity = randomRange(0.3, 0.7, false);
    this.settleLength = 500;
    this.settled = 0;
  };

  snowFlake.prototype.draw = function () {
    this.y += this.yVelocity;
    this.x += this.xVelocity;

    context.current.beginPath();
    context.current.arc(this.x, this.y, this.width / 2, 0, 2 * Math.PI, false);
    context.current.fillStyle = 'rgba(255, 255, 255, ' + this.opacity + ')';
    context.current.fill();

    if (this.y > canvas.current.height) {
      this.xVelocity = 0;
      this.yVelocity = 0;
      this.settled++;

      if (this.settled > this.settleLength) {
        return false;
      }
    }

    return true;
  };

  // Blowing leaf particle
  const blowingLeaf = function () {
    this.type = 'blowing_leaf';
    this.width = randomRange(6, 12);
    this.height = this.width * 1.8;

    this.xVelocity = (windSpeed - randomRange(0, 20)) / 15;
    this.yVelocity = this.xVelocity / 12;

    this.rotation = Math.random() * 1;
    this.rotationVelocity = randomRange(-0.06, 0.06, false);

    if (this.xVelocity > 0) {
      // >>>
      this.x = randomRange(-50, -100);
    } else {
      // <<<
      this.x = randomRange(canvas.current.width, canvas.current.width + 100);
    }

    this.gravity = randomRange(-0.06, 0.06, false);
    this.y = randomRange(canvas.current.height - canvas.current.height / 3, canvas.current.height);
    this.yDirectionChangeLength = randomRange(20, 100);
    this.yDirectionTravelled = 0;
  };

  blowingLeaf.prototype.draw = function () {
    // save context
    context.current.save();

    // move x and y
    this.x += this.xVelocity;
    this.y += this.yVelocity;

    // sway
    this.yVelocity = this.yVelocity + this.gravity + -0.01;

    this.yDirectionTravelled++;
    if (this.yDirectionTravelled > this.yDirectionChangeLength) {
      this.yDirectionTravelled = 0;
      this.gravity *= -1;
      this.yDirectionChangeLength = randomRange(20, 100);
    }

    // increment rotation
    this.rotation += this.rotationVelocity;

    // translate context
    const xOffset = this.width / 2;
    const yOffset = this.height / 2;

    context.current.translate(this.x + xOffset, this.y + yOffset);
    context.current.rotate(this.rotation);
    context.current.drawImage(
      imageAssets.leaf.image,
      0,
      0,
      100,
      224,
      0 - xOffset,
      0 - yOffset,
      this.width,
      this.height,
    );

    // restore context
    context.current.restore();

    if (this.xVelocity > 0) {
      // >>>
      if (this.x > canvas.current.width) {
        return false;
      }
    } else {
      // <<<
      if (this.x < -50) {
        return false;
      }
    }
    return true;
  };

  // Lightning particle
  const lightning = function () {
    this.type = 'lightning';
    this.x = randomRange(0, canvas.current.width);
    this.age = 0;
    this.life = 20;
    this.drawFrom = 0;
    this.drawTo = 0;
    this.points = [[this.x, 0]];
    this.totalPoints = 0;
    this.opacity = 0.7;

    this.flashed = false;
    this.flashOpacity = 0;

    let nextPointX = 0;
    let nextPointY = 0;
    while (nextPointY < canvas.current.height) {
      const lastPoint = this.points[this.points.length - 1];
      nextPointX =
        lastPoint[0] > this.x ? randomRange(this.x, this.x + 15) : randomRange(this.x + 15, this.x);
      nextPointY = lastPoint[1] + randomRange(10, 50);

      if (nextPointY > canvas.current.height) {
        nextPointY = canvas.current.height;
      }

      this.totalPoints++;
      this.points.push([nextPointX, nextPointY]);
    }
  };

  lightning.prototype.draw = function () {
    if (this.drawTo < this.points.length) {
      this.drawTo = this.drawTo + 2;
      if (this.drawTo > this.points.length) {
        this.drawTo = this.points.length;
      }
    } else {
      this.opacity = this.opacity - 0.02;

      if (!this.flashed) {
        this.flashed = true;
        this.flashOpacity = 1;
      }
    }

    if (this.opacity < 0) {
      return false;
    }

    if (this.flashOpacity > 0) {
      context.current.fillStyle = 'rgba(255, 255, 255, ' + this.flashOpacity + ')';
      context.current.fillRect(0, 0, canvas.current.width, canvas.current.height);
      this.flashOpacity = this.flashOpacity - 0.1;
    }

    context.current.beginPath();
    context.current.moveTo(this.points[this.drawFrom][0], this.points[this.drawFrom][1]);

    for (let i = this.drawFrom; i < this.drawTo; i++) {
      context.current.lineTo(this.points[i][0], this.points[i][1]);
    }

    context.current.strokeStyle = 'rgba(255, 255, 255, ' + this.opacity + ')';
    context.current.lineWidth = 0.4;
    context.current.stroke();

    return true;
  };

  return (
    <div className="weather-card" {...(id && { id })}>
      <div className="canvas-outer">
        <canvas
          id="canvas"
          className={`canvas ${conditions.current.time} ${
            weather === 'clouds' ? 'cloud-weather' : ''
          }`}
        ></canvas>
      </div>
      {weather === 'sunny' ? <Sunny time={time} /> : null}
    </div>
  );
};

export default WeatherCard;
