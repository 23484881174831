import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Mobile from '@/pages/mobile';
// import ErrorPage from '@/pages/error';
import './App.css';

function App() {
  return (
    <Routes>
      <Route element={<Mobile />} path={'/'} />
      <Route element={<Mobile />} path={'/:lang'} />
      {/* <Route element={<ErrorPage />} path={'/error'}>
        404 Page
      </Route>
      <Route element={<Navigate to="/error" />} path={'/*'} /> */}
    </Routes>
  );
}

export default App;
