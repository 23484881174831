import React from 'react';
import './index.less';

const ProfileItem = ({ label, value = ' ' }) => {
  return (
    <div className="profile-item">
      <div className="profile-item-content profile-item-label">{label}</div>
      <div className="profile-item-content profile-item-value">{value ? value : ' '}</div>
    </div>
  );
};

export default ProfileItem;
