import React, { useEffect, useState } from 'react';

import { Image } from 'antd';

import './index.scss';

const EmojiImage = ({ imageUrl, log }) => {
  const [styleInstance, setStyleInstance] = useState({ top: 0, left: 0 });
  const [timer, setTimer] = useState(0);

  const getRandom = (min = 0, max = 1) => {
    if (min < 0 || min > 1 || max < 0 || max > 1 || max < min) {
      return;
    }

    while (true) {
      const random = Math.random();

      if (random >= min && random <= max) {
        return random;
      }
    }
  };

  useEffect(() => {
    const displayEmoji = () => {
      if (timer) {
        window.cancelAnimationFrame(timer);
        setTimer(0);
      }

      const clientHeight = document?.body?.clientHeight || 1000;
      const clientWidth = document?.body?.clientWidth || 600;
      const start = Math.random() * (clientWidth - 36);
      const end = Math.random() * (clientWidth - 72);
      const speed = clientHeight / (1000 * getRandom(0.15, 0.45));

      let tempTop = 0;
      let tempLeft = start;

      let count = 0;
      function animation() {
        if (tempTop > clientHeight) {
          return window.cancelAnimationFrame(timer);
        }

        tempTop = count * speed;
        tempLeft = tempLeft + Math.ceil(((end - start) * 2) / (clientHeight / speed));

        setStyleInstance({
          top: tempTop,
          left:
            tempLeft < 0
              ? 0 - tempLeft
              : tempLeft > clientWidth - 36
              ? 2 * clientWidth - tempLeft - 72
              : tempLeft,
        });
        setTimer(window.requestAnimationFrame(animation));
        count++;
      }

      setTimer(window.requestAnimationFrame(animation));
    };

    setTimeout(() => {
      displayEmoji();
    }, Math.random() * 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return imageUrl ? (
    <div className="fall" style={styleInstance}>
      <Image className="emoji-image" src={imageUrl} preview={false} />
    </div>
  ) : null;
};

const Falldown = ({ imageUrl }) => {
  if (!imageUrl) {
    return null;
  }

  const downs = new Array(20).fill();

  //className="falldown"
  return (
    <div className="falldown-card">
      {downs.map((i, index) => (
        <EmojiImage key={index} {...(imageUrl && { imageUrl })}></EmojiImage>
      ))}
    </div>
  );
};

export default Falldown;
