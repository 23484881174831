/**
 * APP内获取网络类型
 * 安卓实现方式
 * @returns 2G 3G 4G 5G wifi
 */
export const getNetworkType = () => {
  try {
    // eslint-disable-next-line no-undef
    return JsBridge?.getNetworkType();
  } catch (error) {
    return null;
  }
};

/**
 * APP内设置屏幕顶部菜单栏颜色
 * 安卓实现方式
 * @returns null
 */
export const updateAndroidMenuColor = (color) => {
  try {
    // eslint-disable-next-line no-undef
    return JsBridge?.updateMenuColor(color);
  } catch (error) {
    return null;
  }
};

/**
 * APP内设置屏幕顶部菜单栏颜色
 * IOS实现方式
 * @returns null
 */
export const updateIOSMenuColor = (color) => {
  try {
    // eslint-disable-next-line no-undef
    return window?.webkit?.messageHandlers?.superUpdateJsHandler?.postMessage({
      action: 'updateMenuColor',
      param: color,
    });
  } catch (error) {
    return null;
  }
};

/**
 * APP内设置屏幕顶部菜单栏颜色
 * 內部區分app实现方式
 * @returns null
 */
export const updateMenuColor = ({ platform, color }) => {
  if (platform === 'ios') {
    updateIOSMenuColor(color);
  }

  if (platform === 'android') {
    updateAndroidMenuColor(color);
  }

  return;
};
