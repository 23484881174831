import React, { useEffect, useState } from 'react';
import { Image } from 'antd';

import './index.less';

import SendLoadingImage from '@/assets/png/icon_loading@2x.png';
import { ReactComponent as SendFailedIcon } from '@/assets/svg/icon_sendfailed.svg';
import timeConstants from '@/constants/timeConstants';

const CommandCard = ({ id, command, sendStatus = 0, errorClick = () => {} }) => {
  const [status, setStatus] = useState(sendStatus);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (sendStatus === 1) {
      setTimeout(() => {
        setStatus(() => 2);
      }, timeConstants.COMMAND_SEND_TIMEOUT);
    }
  });

  return visible ? (
    <div className="command-card fade-in-item">
      <div id={id} style={{ display: 'inline' }}>
        {status === 1 ? (
          <Image className="loading-icon" src={SendLoadingImage} preview={false} async />
        ) : null}
        {status === 2 ? (
          <SendFailedIcon
            className="send-failed-icon"
            onClick={() => {
              setVisible(false);
              errorClick();
            }}
          />
        ) : null}
      </div>
      <div className="command-card-content">
        <span>{command}</span>
      </div>
    </div>
  ) : null;
};

export default CommandCard;
