import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { useParams } from 'react-router-dom';

import WeatherIcon from '@/components/weatherIcon';
import { ReactComponent as IconBackSvg } from '@/assets/svg/icon_back.svg';
import { useProfileContext } from '@/hooks/useProfile';
import { useConfigContext } from '@/hooks/useConfig';
import { simplifyLang } from '@/utils/languageUtil';

import './index.less';

const Header = ({
  // TODO: correct
  logoUrl,
  name,
  connected,
  display,
  weather,
  weatherChanged,
}) => {
  const { t } = useTranslation('common');
  const { platformInfo } = useProfileContext();
  const { config } = useConfigContext();
  const { lang: language = 'tc' } = useParams();
  const lang = simplifyLang(language.toLowerCase());
  const [showModal, setShowModal] = useState(false);

  const onClick = () => {
    setShowModal(true);
  };

  const handleOk = () => {
    setShowModal(false);

    // eslint-disable-next-line no-script-url
    // window.open('javascript:history.go(-1);', '_self');
    platformInfo.isApp && window.open('?appAction=close', '_self');
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div id="chat-header" className="header-wraper">
      <div
        className="page-back-button"
        onClick={() => {
          onClick();
        }}
      >
        <IconBackSvg className="return-icon" />
      </div>
      {display ? (
        <div className="header">
          {connected ? (
            <>
              <div className="bo-info">
                <span className="bo-name">{name}</span>
              </div>
              <div className="weather-icon">
                <WeatherIcon weather={weather} onClick={weatherChanged} />
              </div>
            </>
          ) : (
            <div className="bo-info">
              <span className="bo-name">{t('connectedFailed')}</span>
            </div>
          )}
        </div>
      ) : null}
      <Modal
        visible={showModal}
        width={'85.65%'}
        closable={false}
        centered={true}
        getContainer={() => document.getElementById('root')}
        onCancel={handleCancel}
        wrapClassName="contact-more-modal"
        maskClosable={true}
        footer={[
          <Button key="submit" type="link" onClick={handleOk}>
            {config?.exit_confirmation?.[lang === 'en' ? 'confirm_btn_en' : 'confirm_btn_tc'] ||
              t('exit.confirm')}
          </Button>,
          <Button key="back" type="link" onClick={handleCancel}>
            {config?.exit_confirmation?.[lang === 'en' ? 'cancel_btn_en' : 'cancel_btn_tc'] ||
              t('exit.cancel')}
          </Button>,
        ]}
      >
        <p>
          {config?.exit_confirmation?.[lang === 'en' ? 'description_en' : 'description_tc'] ||
            t('exit.content')}
        </p>
      </Modal>
    </div>
  );
};

export default Header;
