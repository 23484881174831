import React, { useEffect } from 'react';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.less';

import LoadingImage from '@/assets/png/img_loading_845.png';
import { ReactComponent as CloseIcon } from '@/assets/svg/icon_close.svg';
import timeConstants from '@/constants/timeConstants';

const ConnectCard = ({ reconnect = false, done, display }) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    const line = document.getElementById('loading-line');

    if (display) {
      const mask = document.getElementById('connecting-card');
      mask.style.display = 'block';
      line.style.width = '0%';
    }

    if (done) {
      line.style.width = '100%';

      setTimeout(() => {
        onClose();
      }, timeConstants.CONNECTING_STATUS_KEEP);
    }
  }, [display, done]);

  const onClose = () => {
    const mask = document.getElementById('connecting-card');
    mask.style.display = 'none';
  };

  return (
    <div
      id="connecting-card"
      className={`connecting-card ${reconnect ? 'recon-card' : 'first-con-card'}`}
    >
      <div className="conntect-content">
        <div className="connecting-image">
          <Image src={LoadingImage} preview={false} async />
        </div>
        <div className="loading-process">
          <div id="loading-line" className="loading-line"></div>
        </div>
        <div className="loading-sumary">
          <span>{t('connecting')}</span>
        </div>
        {reconnect ? (
          <div className="close-button" onClick={onClose}>
            <CloseIcon className="close-icon" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ConnectCard;
