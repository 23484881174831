import React, { useState, useEffect } from 'react';
import { Image } from 'antd';
import cookie from 'react-cookies';

import { useProfileContext } from '@/hooks/useProfile';
import { functionBtnClick } from '@/utils/gtm';
import { replaceLang } from '@/utils/deeplink';
import { clearNotification } from '@/client/chabot';
import { useAuthContext } from '@/hooks/useAuth';

import './index.less';

const IconCard = ({ refId, refData, imageUrl, summary, onClick, href }) => {
  const { userProfile, lang } = useProfileContext();
  const [clicking, setClicking] = useState(false);
  const { authInfo = {} } = useAuthContext();
  const [badgeData, setBadgeData] = useState(null);
  const { accessToken: token } = authInfo;

  useEffect(() => {
    setBadgeData(refData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refData]);

  const clickHandler = async () => {
    setBadgeData(null);
    if (clicking) {
      return;
    }

    setClicking(true);
    setTimeout(() => {
      const link = replaceLang({ deeplink: href, lang });
      console.log('function deeplink', href, link);

      setClicking(false);
      onClick && onClick();
      href && window.open(link, '_self');
    }, 500);

    // data tracking
    functionBtnClick({
      bossID: userProfile?.boss_id,
      profileID: userProfile?.extra?.profile_id,
      label: summary,
      redirect: userProfile?.device_os,
    });

    if (badgeData) {
      cookie.save(
        `Badge-Func-${refId}`,
        `${badgeData},${userProfile?.extra?.profile_id},${userProfile?.boss_id}`,
      );

      try {
        await clearNotification({ lang, token, badgeInfo: { fun_id: refId } });
      } catch (error) {
        console.warn('Clear badge error', refId, error);
      }
    }
  };

  return (
    <div className="icon-card" onClick={clickHandler}>
      <div className={`icon-image-container ${clicking ? 'jelly-image' : ''}`}>
        <div
          className="icon-image-container-bottom"
          style={{ backgroundImage: `url(${imageUrl})` }}
        ></div>
        <Image className={`icon-card-image `} src={imageUrl} alt={summary} preview={false} />
        {badgeData ? <span className="badge-dot"></span> : null}
      </div>
      <div className="icon-card-summary">
        <p
          style={{
            margin: 0,
            textAlign: 'center',
            ...(lang === 'tc'
              ? {
                  width: '6em',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }
              : {
                  width: '6em',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: '2',
                  overflow: 'hidden',
                }),
          }}
        >
          {summary}
        </p>
      </div>
    </div>
  );
};

export default IconCard;
