import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Button, ConfigProvider, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cookie from 'react-cookies';

import { useProfileContext } from '@/hooks/useProfile';
// import { ReactComponent as IconMenuMoreSvg } from '@/assets/svg/icon_menu_more.svg';
import getPlatform from '@/utils/platform';
import FuncMenu from '@/components/funcMenu';
import { useConfigContext } from '@/hooks/useConfig';
import { simplifyLang } from '@/utils/languageUtil';
import './index.less';

function Footer({
  inputRef,
  functions,
  disabled,
  badgeInfo = [],
  onPressEnter = () => {},
  onFocusHandler = () => {},
  onBlurHandler = () => {},
  onMenuDislay = () => {},
}) {
  const [message, setMessage] = useState('');
  const [showSendBtn, setShowSendBtn] = useState(true);
  const inputStatus = useRef(0);
  const isEventHandler = useRef(false);
  const readyStatus = useRef(false);
  const resizing = useRef(false);
  const viewPortHeightRef = useRef(0);
  const [showMenus, setShowMenus] = useState(true);
  const [badge, setBadge] = useState({});
  const { t } = useTranslation('common');
  const { config } = useConfigContext();

  const { lang: language = 'tc' } = useParams();
  const lang = simplifyLang(language.toLowerCase());
  const { userProfile } = useProfileContext();

  const viewport = window.visualViewport;
  const platform = getPlatform();

  const formatBadge = useCallback(() => {
    if (!badgeInfo || !badgeInfo.length) {
      return;
    }

    const profileId = userProfile?.extra?.profile_id;
    const bossId = userProfile?.boss_id;

    let info = {};
    (badgeInfo || []).forEach((item) => {
      const cookieData = String(cookie.load(`Badge-Func-${item.fun_id}`) || '').split(',');
      const hideBadge =
        Number(cookieData?.[0] || 0) >= item.version &&
        String(profileId) === cookieData?.[1] &&
        String(bossId) === cookieData?.[2];

      info = {
        ...info,
        ...(!hideBadge && { [item.fun_id]: item.version }),
      };
    });

    const total = Object.keys(info).length || 0;

    setBadge({ info, total });
  }, [badgeInfo, userProfile]);

  // 输入值变化
  const onValueChange = (e) => {
    const value = e.target.value;
    setMessage(value);
  };

  // 发送消息
  const send = () => {
    if (!message.trim()) {
      return;
    }

    onBlur();
    enterHandler(message);
    setMessage('');
    // setShowSendBtn(false);
  };

  // 消息列表滚动到最底部
  const scrollToRow = () => {
    const chatBody = document.getElementById('message-list');
    if (chatBody) {
      chatBody.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  };

  const hideFuncMenus = () => {
    // 为了将底部组件往上推防止footer被往下扩张
    const footer = document.getElementById('footer');
    setShowMenus(false);
    footer.style.bottom = `0px`;
    // footer.style.transform = 'translateY(29.3em)';
    updateChatbodyHeight({ height: '100%' });
    const messageList = document.getElementById('message-list');
    if (messageList) {
      messageList.style.paddingBottom = '6.4em';
    }
  };

  const displayFunctionMenus = () => {
    const footer = document.getElementById('footer');
    setShowMenus(true);
    footer.style.bottom = '0px';
    // footer.style.transform = 'translateY(0px)';
    onFocusHandler();
    // 显示menus时显示消息列表底部
    updateChatbodyHeight({ height: '100%' });
    onMenuDislay();

    const messageList = document.getElementById('message-list');
    if (messageList) {
      messageList.style.paddingBottom = '35.6em';
    }
    scrollToRow();
    // 聚焦在功能菜单时
  };

  // 是否显示function 菜单列表
  // const onMenusStatusHandler = useCallback(
  //   ({ show, ori }) => {
  //     const isDisplay = show !== undefined ? show : !showMenus;
  //     console.log('function menus display status...', ori, show, showMenus, isDisplay, platform);

  //     // 为了将底部组件往上推防止footer被往下扩张
  //     if (!isDisplay) {
  //       hideFuncMenus();
  //     } else if (!resizing.current) {
  //       // 不处于输入状态时可以显示menus
  //       displayFunctionMenus();
  //     } else {
  //       // 不处于输入状态时可以显示menus
  //       readyStatus.current = isDisplay;
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [onFocusHandler, showMenus],
  // );

  // 移动端适配软键盘抬起页面
  const handleViewResize = () => {
    resizing.current = false;
    document.body.style.height = `${viewport.height}px`;

    const footer = document.getElementById('footer');
    if (footer) {
      footer.style.bottom = `0px`;
    }

    if (inputStatus.current !== 0) {
      window.scrollTo(0, 0);

      setTimeout(() => {
        scrollToRow();
      }, 100);
    }

    if (inputStatus.current === 2) {
      inputStatus.current = 0;
    }

    const messageFirst = document.getElementById('message-first');
    updateChatbodyHeight(!messageFirst || messageFirst.offsetHeight <= 0 ? { height: '100%' } : {});
    scrollToRow();

    if (readyStatus.current) {
      readyStatus.current = false;
    }
    setTimeout(() => {
      if (viewPortHeightRef.current <= viewport.height) {
        displayFunctionMenus();
      } else {
        hideFuncMenus();
      }
    }, 100);
  };

  // 阻止IOS 中页面可视区域随意滑动，尤其是软键盘弹出后
  const preventIosViewExtraScroll = (event) => {
    if (inputStatus.current !== 1) {
      return;
    }
    const path = event.path || (event.composedPath && event.composedPath());

    if (path && Array.isArray(path)) {
      event.preventDefault();
    }
  };

  // 输入框聚焦
  const onFocus = () => {
    inputStatus.current = 1;
    scrollToRow();
    // hideFuncMenus();
    onFocusHandler();

    setShowSendBtn(true);
    resizing.current = platform !== 'web';

    handleViewResize();
  };

  // 取消聚焦
  const onBlur = () => {
    console.log('onBlur.....');
    onBlurHandler();
    inputRef?.current?.blur();

    inputStatus.current = 2;
    // setShowSendBtn(!!message);
    handleViewResize();
    resizing.current = platform !== 'web';
    updateChatbodyHeight({ height: '100%' });
    // displayFunctionMenus();
  };

  // 显示function菜单时和软键盘打开时抬起聊天内容
  const updateChatbodyHeight = ({ height, full } = {}) => {
    const footer = document.getElementById('footer');
    const chatBody = document.getElementById('chat-body');

    if (height) {
      chatBody.style.height = height;
      return;
    }

    if (inputStatus.current === 1) {
      chatBody.style.height = `100%`;
      return;
    }

    if (full) {
      chatBody.style.height = `${viewport.height - footer.offsetHeight}px`;
    } else {
      chatBody.style.height = 'calc(100% - 104px)';
    }
  };

  const enterHandler = (e) => {
    // hideFuncMenus();
    onPressEnter(e);
  };

  useEffect(() => {
    if (showMenus) {
      updateChatbodyHeight({ height: '100%' });
      onMenuDislay();
      // } else {
        // updateChatbodyHeight({ height: '100%' });
    }

    formatBadge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenus, formatBadge]);

  // 点击或触摸 消息内容时隐藏function menu
  useEffect(() => {
    if (viewPortHeightRef.current === 0) {
      viewPortHeightRef.current = viewport?.height || 0;
    }
    // const header = document.getElementById('chat-header');
    // const messageList = document.getElementById('message-list');

    if (!isEventHandler.current) {
      // 触摸或点击其他位置是隐藏功能菜单
      // messageList.addEventListener('touchstart', hideFuncMenus, { useCapture: true });
      // messageList.addEventListener('click', hideFuncMenus, { useCapture: true });
      // header.addEventListener('click', hideFuncMenus, { useCapture: true });
      // 适应手机软键盘弹出后可视区域的变化
      viewport.addEventListener('resize', handleViewResize);
      // IOS 系统禁止页面默认的上拉回弹
      platform === 'ios' &&
        window.addEventListener('touchmove', preventIosViewExtraScroll, { passive: false });

      return () => {
        // messageList.removeEventListener('touchstart', hideFuncMenus, { useCapture: true });
        // messageList.removeEventListener('click', hideFuncMenus, { useCapture: true });
        // header.removeEventListener('click', hideFuncMenus, { useCapture: true });
        viewport.removeEventListener('resize', handleViewResize);
        window.removeEventListener('touchmove', preventIosViewExtraScroll, { passive: false });
      };
    } else {
      isEventHandler.current = true;
    }
  });

  return (
    <div id="footer" className={`footer`}>
      <div id="footerInput" className="footer-wraper">
        {/* {functions && functions.length > 0 ? (
          <div className="func-menus-icon" onClick={onMenusStatusHandler}>
            <IconMenuMoreSvg
              style={{
                ...(showMenus && {
                  transform: 'rotate(45deg)',
                }),
              }}
            />
            {badge?.total ? <span className="badge-dot"></span> : null}
          </div>
        ) : null} */}
        <div
          className="footer-wraper-input"
          style={{
            // ...((!functions || functions.length < 1) && {
            width: 'calc(100% - 1.4em)',
            marginLeft: '1.4em',
            // }),
          }}
        >
          <Input
            ref={inputRef}
            className="footer-input"
            disabled={disabled}
            bordered={false}
            value={message}
            placeholder={config.input_prompt?.[lang] || ''}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onValueChange}
            onKeyUp={(e) => {
              if (e && e.keyCode === 13) {
                send();
              }
            }}
          ></Input>
          {showSendBtn ? (
            <div className="button-wraper">
              <ConfigProvider autoInsertSpaceInButton={false}>
                <Button
                  className={`message-send-button ${message ? 'message-ready-button' : ''}`}
                  onClick={send}
                >
                  <span>{t('send')}</span>
                </Button>
              </ConfigProvider>
            </div>
          ) : null}
        </div>
      </div>
      {showMenus ? (
        <FuncMenu functions={functions} badge={badge} clickHandler={enterHandler} />
      ) : null}
    </div>
  );
}

export default Footer;
