/* eslint-disable jsx-a11y/alt-text */

import * as R from 'ramda';

import { getDayOrNight } from '@/utils/dateTimeUtil';
import { DEFAULT_WEATHER } from '@/constants/weather';

import './index.less';

const Coin = ({ src, weather }) => {
  const classMap = {
    sunny: {
      day: 'coin-day',
      night: 'coin-night-pinkle',
    },
    clouds: {
      day: 'coin-day',
      night: 'coin-night',
    },
    rain: {
      day: 'coin-day',
      night: 'coin-night',
    },
    wind: {
      day: 'coin-day',
      night: 'coin-night',
    },
    lightning: {
      day: 'coin-night',
      night: 'coin-night',
    },
  };

  const time = getDayOrNight();
  const bottomClass =
    R.pathOr(classMap[DEFAULT_WEATHER].day, [weather, time], classMap) ||
    classMap[DEFAULT_WEATHER].day;

  return (
    <div className="logo-coin">
      <div className="coin">
        <figure className="coin-1"></figure>
        <figure className="coin-2"></figure>
        <figure className="coin-3"></figure>
        <figure className="coin-4"></figure>
        <figure className="coin-5"></figure>
        <figure className="coin-6"></figure>
        <figure className="coin-7"></figure>
        <figure className="coin-8"></figure>
        <figure className="coin-9"></figure>
        <figure className="coin-10"></figure>
        <figure className="coin-11"></figure>
        <figure className="coin-12"></figure>
        <figure className="coin-13"></figure>
        <figure className="coin-14"></figure>
        <figure className="coin-15"></figure>
        <figure className="coin-16"></figure>
        <figure className="coin-17"></figure>
        <figure className="coin-18"></figure>
        <figure className="coin-19"></figure>
        <figure className="coin-20"></figure>
        <figure className="coin-21"></figure>
        <figure className="coin-22"></figure>
        <figure className="coin-23"></figure>
        <figure className="coin-24"></figure>
        <figure className="coin-25"></figure>
        <figure className="coin-26"></figure>
        <figure className="coin-27"></figure>
        <figure className="coin-28"></figure>
        <figure className="coin-29"></figure>
        <figure className="coin-30"></figure>
        <figure className="coin-31"></figure>
        <figure className="coin-32"></figure>
        <figure className="coin-33"></figure>
        <figure className="coin-34"></figure>
        <figure className="coin-35"></figure>
        <figure className="coin-36"></figure>
        <figure className="coin-37"></figure>
        <figure className="coin-38"></figure>
        <figure className="coin-39"></figure>
        <figure className="coin-40"></figure>
        <figure className="coin-41"></figure>
        <figure className="coin-42"></figure>
        <figure className="coin-43"></figure>
        <figure className="coin-44"></figure>
        <figure className="coin-45"></figure>
        <figure className="coin-46"></figure>
        <figure className="coin-47"></figure>
        <figure className="coin-48"></figure>
        <figure className="coin-49"></figure>
        <figure className="coin-50"></figure>
        <figure className="coin-51"></figure>
        <figure className="coin-52"></figure>
        <figure className="coin-53"></figure>
        <figure className="coin-54"></figure>
        <figure className="coin-55"></figure>
        <figure className="coin-56"></figure>
        <figure className="coin-57"></figure>
        <figure className="coin-58"></figure>
        <figure className="coin-59"></figure>
        <figure className="coin-60"></figure>
        <figure className="coin-61"></figure>
        <figure className="coin-62"></figure>
        <figure className="coin-63"></figure>
        <figure className="coin-64"></figure>
        <figure className="coin-65"></figure>
        <figure className="coin-66"></figure>
        <figure className="coin-67"></figure>
        <figure className="coin-68"></figure>
        <figure className="coin-69"></figure>
        <figure className="coin-70"></figure>
        <figure className="coin-71"></figure>
        <figure className="coin-72"></figure>
        <figure className={`coin-top ${bottomClass}`}>
          <img src={src} />
        </figure>
        <figure className={`coin-bottom ${bottomClass}`}>
          <img src={src} />
        </figure>
      </div>
    </div>
  );
};

export default Coin;
