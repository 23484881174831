export const customer = {
  account: {
    bossId: '客戶號碼',
    name: '中文姓名',
    email: '電郵地址',
    phone: '手提電話號碼',
    tvbAccount: 'TVB 會員賬戶',
    tvbAccountEmail: 'TVB會員賬戶電郵',
  },
  button: {
    changePassword: '更改賬戶密碼',
    changeBookingPassword: '更改訂購密碼',
    forgetPassword: '忘記賬戶密碼',
    forgetBookingPassword: '忘記訂購密碼',
    next: '下一步',
    confirm: '確認',
    close: '關閉',
    cancel: '返回',
  },
  copied: '複製成功!',
  label: {
    // 賬戶密碼
    account: {
      currentPassword: '現有賬戶密碼',
      newPassword: '新賬戶密碼',
      newConfirmPassword: '重複新賬戶密碼',
      changePassword: '更改賬戶密碼',
      forgetPassword: '忘記賬戶密碼',
      passwordFormator: '*密碼必須為4個數字',
      passwordSuccess: '已成功更改賬戶密碼',
      forgetPasswordPrefix: '請輸入完整電話號碼，以核對身份。',
      forgetPasswordLabel: '如輸入的電話號碼正確，你將於登記電話號碼收到新賬戶密碼短訊。',
      forgetPasswordSuccess: '賬戶密碼已經由短訊發送至你的流動電話號碼',
    },
    // 訂購密碼
    booking: {
      currentPassword: '現有訂購密碼',
      newPassword: '新訂購密碼',
      newConfirmPassword: '重複新訂購密碼',
      changePassword: '更改訂購密碼',
      forgetPassword: '忘記訂購密碼',
      passwordFormator: '*密碼必須為4個數字',
      passwordSuccess: '已成功更改訂購密碼',
      forgetPasswordPrefix: '請輸入完整電話號碼，以核對身份。',
      forgetPasswordLabel: '如輸入的電話號碼正確，你將於登記電話號碼收到新賬戶密碼短訊。',
      forgetPasswordSuccess: '訂購密碼已經由短訊發送至你的流動電話號碼',
    },
    // email賬戶密碼
    tvbAccount: {
      currentPassword: '現有賬戶密碼',
      newPassword: '新賬戶密碼',
      newConfirmPassword: '重複新賬戶密碼',
      changePassword: '更改賬戶密碼',
      forgetPassword: '忘記賬戶密碼',
      passwordFormator: '*密碼必須包括至少 6個字元，英文字母或數字',
      passwordSuccess: '已成功更改賬戶密碼',
      forgetPasswordPrefix: '請輸入完整電郵，以核對身份。',
      forgetPasswordLabel: '如輸入正確，你將於該郵箱收到重設密碼電郵。',
      forgetPasswordInput: 'TVB會員賬戶電郵',
      forgetPasswordSuccess: '重設密碼電郵已發送至您的電子郵箱',
    },
    error: {
      passwordIncorrect: '現有密碼不正確，請重新輸入',
      fourPasswordIncorrect: '密碼必須為4位數字，請重新輸入',
      confirmPasswordIncorrect: '您輸入的兩個密碼並不相符，請再試一次。',
      limitFiveError: '密碼多次輸入不正確，',
      contactLabel: '請致電客戶服務熱線',
      contactPhone: '2399-9666',
      contactLabelLater: '',
      newPasswordDuplicated: '新密碼不得與舊密碼重複，請重新輸入',
      phoneIncorrect: '流動電話號碼錯誤，請重新輸入',
      sixPasswordIncorrect: '密碼必須包括至少 6個字元，英文字母或數字，請重新輸入',
      emailIncorrect: 'TVB會員賬戶電郵錯誤，請重新輸入',
    },
  },
};
