import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import 'minirefresh/dist/debug/minirefresh.css';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import VConsole from 'vconsole';
import config from '@/config';
import { message } from 'antd';

import { ReactComponent as TipsIcon } from '@/assets/svg/icon_tips.svg';
import { ReactComponent as RefreshIcon } from '@/assets/svg/icon_refresh.svg';
import Header from '@/components/header';
import TimeShower from '@/components/timeShower';
import ConnectCard from '@/components/connectCard';
import ConnectFailed from '@/components/connectFailed';
import UnloginCard from '@/components/unloginCard';
import CommandCard from '@/components/commandCard';
import WaitingCard from '@/components/waitingCard';
import Fireworks from '@/components/fireworks';
import MoreModule from '@/components/moreModule';
import Falldown from '@/components/falldown';
import WeatherCard from '@/components/weatherCard';
import MessageHeader from '@/components/messageHeader';
import PullLoad from '@/components/pullLoad';
import timeConstants from '@/constants/timeConstants';
import { getTemplate } from '@/containers';
import Introduction from '@/containers/introduction';
import Recommend from '@/containers/recommend';
import HelmetDiv from '@/containers/helmetDiv';
import Footer from '@/containers/footer';
import { getHistory, getProgrammeLabels } from '@/client/chabot';
import { useAuthContext } from '@/hooks/useAuth';
import { useProfileContext } from '@/hooks/useProfile';
import { sendExceptions } from '@/hooks/utils/exception';
import { initSocket } from '@/utils/webSocket';
import { formatLang, setLang, simplifyLang } from '@/utils/languageUtil';
import { pageview } from '@/utils/gtm';
import { getDayOrNight } from '@/utils/dateTimeUtil';
import { updateMenuColor } from '@/utils/appFunction';

import './index.less';
import moment from 'moment';

const HISTORY_MESSAGE_LIMIT = 10;

const ConnectStatusMap = {
  NONE: 'none',
  START: 'start',
  RESTART: 're-start',
  DONE: 'done',
  ERROR: 'error',
  RE_ERROR: 're-error',
};

const sliceRecords = ({ records = [], firstLength = 5, hideLength = 5 }) => {
  const sliceCount =
    records.length < firstLength ? 1 : Math.floor((records.length - firstLength) / hideLength) + 1;

  let slices = [];
  for (let index = 0; index < sliceCount; index++) {
    if (index === 0) {
      slices.push(records.slice(0, firstLength));
    } else {
      slices.push(
        records.slice(firstLength + (index - 1) * hideLength, firstLength + index * hideLength),
      );
    }
  }
  console.log({ slices });

  return slices;
};

const dynamicMore = (type, records) => {
  if (type === 'program') {
    const slices = sliceRecords({ records, firstLength: 2, hideLength: 4 });

    return <MoreModule currentId={nanoid()} key={nanoid()} records={slices} />;
  }

  return <div key={nanoid()}>{records.map((record) => record.template)}</div>;
};

const getModules = ({
  modules,
  userProfile,
  platformInfo,
  lang,
  onFunctionClick,
  locker,
  lockCustomer,
  programmeLabels = {},
}) => {
  let list = [];
  let merged = [];

  if (!modules) {
    return null;
  }

  const realModules = modules
    ?.reduce((sum, module) => {
      try {
        const temp = getTemplate({
          data: module,
          userProfile,
          platformInfo,
          lang,
          onFunctionClick,
          programmeLabels,
          locker,
          lockCustomer,
        });

        return temp && temp.length > 0 ? [...sum, ...temp] : [...sum, temp];
      } catch (err) {
        console.log('Get template failed', err);
        return sum;
      }
    }, [])
    .filter((item) => item && !!item.template);

  realModules.forEach((module, index) => {
    const last = merged[merged.length - 1];

    if (last && last.type !== module?.type) {
      list.push(dynamicMore(last.type, merged));
      merged = [module];
    } else {
      merged.push(module);
    }

    if (index === realModules.length - 1) {
      list.push(dynamicMore(merged[merged.length - 1].type, merged));
    }
  });

  return list;
};

const Mobile = () => {
  const { lang: language = 'tc' } = useParams();
  const lang = simplifyLang(language.toLowerCase());

  const socket = useRef(null);
  const socketOnline = useRef(false);
  const socketKicked = useRef(false);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [showHeader, setShowHeader] = useState(false);
  const [fireworksEnable, setFireworksEnable] = useState(false);
  const [falldownVisible, setFalldownVisible] = useState(false);
  const [connectStatus, setConnectStatus] = useState(ConnectStatusMap.START);
  const userLocked = useRef({});
  const times = useRef({ last: null, now: null });
  const introductionRef = useRef(false);
  const waitingQueue = useRef([]);
  const inputRef = useRef();
  const inputList = useRef({});
  const firstHistoryId = useRef();
  const currentContainerId = useRef(0);
  const programmeLabelling = useRef({});
  const falldownImage = useRef();
  const falldownTimer = useRef();
  const historyRequesting = useRef(false);

  const { authInfo = {}, clientToken } = useAuthContext();
  const { accessToken: token, loginStatus, mzToken } = authInfo;
  const { baseInfo, userProfile, platformInfo } = useProfileContext();
  // TODO: for testing weather
  const [weatherInfo, setWeatherInfo] = useState(baseInfo?.weather || {});

  const { t } = useTranslation('common');

  const setUserLocked = ({ login, booking }) => {
    if (login) {
      userLocked.current = { ...userLocked.current, login: true };
    }
    if (booking) {
      userLocked.current = { ...userLocked.current, booking: true };
    }
  };

  const buildSocket = useCallback(
    async ({ token: accessToken, isReconnect }) => {
      if (Object.keys(programmeLabelling.current).length < 1) {
        getProgrammeLabels({ lang, token, platform: platformInfo?.platformType })
          .then((res) => {
            const labels = res.data?.programme_labels?.reduce((acc, item) => {
              return { ...acc, [item.programme_id]: item.free_episode };
            }, {});
            programmeLabelling.current = labels;
          })
          .catch((e) => console.error('Get pogramme free episode failed', e));
      }
      if ((!socket.current || !socketOnline.current) && accessToken) {
        socket.current?.close();
        socketKicked.current = true;
        socket.current = null;
        socketOnline.current = false;

        if (isReconnect) {
          setConnectStatus(ConnectStatusMap.RESTART);
        }

        const socketTimeoutId = setTimeout(() => {
          if (!socketOnline.current) {
            console.error('Socket connect/re-connect failed timeout');
            setConnectStatus(isReconnect ? ConnectStatusMap.RE_ERROR : ConnectStatusMap.ERROR);

            sendExceptions({
              userProfile,
              token,
              langPrefix: lang,
              error: 'Socket connect/re-connect failed timeout',
            });
          }
        }, timeConstants.SOCKET_TIMEOUT);

        const socketInstance = await initSocket({
          url: config.ws,
          lang,
          token: accessToken,
          mzToken: mzToken,
          clientToken,
          heartTimeInSecond: config.heartTimeInSecond,
          isApp: platformInfo.isApp,
        }).catch((error) => {
          sendExceptions({
            userProfile,
            token,
            langPrefix: lang,
            error: 'Socket connect/re-connect error ' + error.message,
          });

          setConnectStatus(isReconnect ? ConnectStatusMap.RE_ERROR : ConnectStatusMap.ERROR);
          clearTimeout(socketTimeoutId);
        });

        if (!socketInstance) {
          console.log('get socket null...');
          return;
        }

        if (socketInstance.readyState === 1) {
          clearTimeout(socketTimeoutId);
          setConnectStatus(ConnectStatusMap.DONE);
          socketOnline.current = true;

          // 掉线重连后补发的应答
          if (isReconnect) {
            const queue = waitingQueue.current || [];
            waitingQueue.current = [];
            queue.forEach((item) => {
              socketInstance.emit('message', { id: item.id, keyword: item.keyword });
            });
          }
        }

        // fix: remove
        socketInstance.onError = (error) => {
          sendExceptions({
            userProfile,
            token,
            langPrefix: lang,
            error: 'Socket error event' + error,
          });
        };

        socketInstance.onclose = (e) => {
          console.log(
            '........disconnected',
            socketInstance.nanoid,
            socketInstance.id,
            new Date(),
            e,
          );

          socketOnline.current = false;
          socket.current = null;

          if (!socketKicked.current) {
            buildSocket({ token, isReconnect: true });
          }
        };

        socketInstance.onmessage = (eventMessage) => {
          const messageData = JSON.parse(eventMessage?.data) || {};
          const { id: msgId, event, payload } = messageData;
          const data = { ...(msgId && { msgId }), ...payload };

          // omit heart beat response
          if (['pong'].includes(event)) {
            return;
          }

          console.log({ event, data });
          if (event === 'offline') {
            socketOffline();

            socketKicked.current = true;
            socketInstance?.close();
          }

          if (['confirm', 'message', 'introduction'].includes(event)) {
            const dataId = R.path(['id'], data);
            const sendTime = inputList.current[dataId];

            if (!firstHistoryId.current) {
              firstHistoryId.current = data.msgId;
            }

            if (event === 'confirm' || (isReconnect && event === 'introduction')) {
              socketOnline.current = true;
              clearTimeout(socketTimeoutId);
              const doc = document.getElementById(dataId);

              if (doc) {
                if (moment().diff(sendTime) < 300) {
                  setTimeout(() => {
                    doc.style.display = 'none';
                  }, 300);
                } else {
                  doc.style.display = 'none';
                }
              }
              return null;
            }

            if (moment().diff(sendTime) < 500) {
              setTimeout(() => {
                handleMessage(data, event);
              }, 500);
            } else {
              handleMessage(data, event);
            }

            const animation = data?.animation;
            if (animation) {
              setFalldownVisible(false);
              setFireworksEnable(false);

              const timer = falldownTimer.current;
              if (timer) {
                clearTimeout(timer);
              }
            }

            if (animation?.type_id === 1) {
              setFireworksEnable(true);
            }

            if (animation?.type_id === 2) {
              falldownImage.current = animation.image_url;
              setFalldownVisible(true);

              falldownTimer.current = setTimeout(() => {
                setFalldownVisible(false);
              }, 10000);
            }

            console.log({ fireworksEnable, falldownVisible, animation, message });
          }
        };

        socket.current = socketInstance;
        if (!isReconnect) {
          displayWeather();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientToken, falldownVisible, fireworksEnable, token, userProfile, weatherInfo],
  );

  const handleMessage = (data, event) => {
    setSending(() => false);

    const time = new Date();
    times.current = { last: times.current.now, now: time };

    setMessageList((messages) => [
      ...messages,
      <Message
        key={nanoid()}
        message={{ type: event, data, time }}
        lastTime={times.current.last}
        locker={userLocked.current}
        lockCustomer={({ login, booking }) => setUserLocked({ login, booking })}
      />,
    ]);

    setTimeout(() => {
      scrollToRow();
    }, 300);
  };

  const getHistoryList = async () => {
    if (historyRequesting.current) {
      console.log('waiting last history request finish...');
      return;
    }
    const id = nanoid();
    try {
      // display loading icon
      setLoading(true);
      historyRequesting.current = true;

      const history = await getHistory({
        lang,
        token,
        limit: HISTORY_MESSAGE_LIMIT,
        startId: firstHistoryId.current,
      });

      const { list } = history.data;
      if (!R.isEmpty(list)) {
        const messageComps = list.map((item, index) => {
          const { id: msgId, from, created_at: createdAt, payload } = item;
          if (index === 0) {
            firstHistoryId.current = msgId;
          }

          const message = JSON.parse(payload);
          setLoading(false);
          historyRequesting.current = false;
          return (
            <Message
              key={nanoid()}
              message={{
                data: message,
                time: createdAt * 1000,
                type: from === 'server' ? 'message' : 'keyword',
              }}
              lastTime={index === 0 ? null : list[index - 1].created_at * 1000}
              locker={userLocked.current}
              lockCustomer={({ login, booking }) => setUserLocked({ login, booking })}
            />
          );
        });

        setMessageList((messageList) => [
          <div id={id} key={id}>
            {messageComps.map((item) => item)}
          </div>,
          ...messageList,
        ]);

        console.log({ current: currentContainerId.current, id });
        scrollToTop(currentContainerId.current);
        // Duplicated scroll to avoid empty screen in iphone11
        setTimeout(() => {
          scrollToTop(currentContainerId.current);
          currentContainerId.current = id;
        }, 100);
      }
    } catch (err) {
      if (!navigator.onLine) {
        message.error({
          icon: <TipsIcon className="tips-icon" />,
          className: 'toast-tips-content',
          getContainer: () => document.getElementById('root'),
          content: t('network.offline'),
          duration: 2,
        });
      } else {
        message.error({
          icon: <TipsIcon className="tips-icon" />,
          className: 'toast-tips-content',
          getContainer: () => document.getElementById('root'),
          content: t('historyFailed'),
          duration: 2,
        });
      }

      sendExceptions({
        userProfile,
        token,
        langPrefix: lang,
        error: 'Failed to pull history message' + err.message,
      });
    }
    setLoading(false);
    historyRequesting.current = false;
  };

  // 显示天气
  const displayWeather = () => {
    setShowHeader(false);

    // 显示天气模块
    const weather = document.getElementById('weather-card');
    const messageFirst = document.getElementById('message-first');
    if (weather) {
      weather.style.display = 'block';
    }
    if (messageFirst) {
      messageFirst.style.display = 'block';
    }

    // 调整滚动区域到顶部距离
    const scrollDoc = document.getElementById('scroll');
    if (scrollDoc) {
      scrollDoc.style.top = '50px';
    }

    // 调整消息区域高度
    const chatBody = document.getElementById('chat-body');
    if (chatBody) {
      chatBody.style.paddingTop = '0em';
      chatBody.style.overflowY = 'hidden';
      chatBody.style.height = 'calc(100% - 104px)';
    }

    // 下拉加载区域到底顶部距离
    const refreshBody = document.getElementById('refresh-body');
    if (refreshBody) {
      refreshBody.style.paddingTop = '0em';
    }

    // 更新APP顶部栏颜色
    const time = getDayOrNight();
    const weatherType = weatherInfo?.weather_type;
    let menuColor = '#150c16';
    if (time === 'day' && weatherType !== 'lightning') {
      menuColor = '#77bbfd';
    } else if (time === 'night' && weatherType === 'sunny') {
      menuColor = '#1F194A';
    } else if (time === 'night' && weatherType === 'wind') {
      menuColor = '#150c16';
    } else if (time === 'night' && weatherType === 'clouds') {
      menuColor = '#150c16';
    }
    updateMenuColor({ color: menuColor, platform: platformInfo?.platformType });
  };

  // 隐藏天气
  const hideWeather = () => {
    setShowHeader(true);

    // 隐藏天气模块
    const weather = document.getElementById('weather-card');
    const messageFirst = document.getElementById('message-first');
    if (weather) {
      weather.style.display = 'none';
    }
    if (messageFirst) {
      messageFirst.style.display = 'none';
    }

    // 调整滚动区域到顶部距离
    const scrollDoc = document.getElementById('scroll');
    if (scrollDoc) {
      scrollDoc.style.top = '0px';
    }

    // 调整消息区域高度
    const chatBody = document.getElementById('chat-body');
    if (chatBody) {
      chatBody.style.paddingTop = '0em';
      chatBody.style.overflowY = 'scroll';
      chatBody.style.height = '100%';
    }

    // 下拉加载区域到底顶部距离
    const refreshBody = document.getElementById('refresh-body');
    if (refreshBody) {
      refreshBody.style.paddingTop = '4.8em';
    }

    // 更新APP顶部栏颜色
    updateMenuColor({ color: '#111111', platform: platformInfo?.platformType });
  };

  const socketOffline = () => {
    if (socket.current) {
      socket.current?.stopHeart();
      socket.current?.close();
    }

    socketOnline.current = false;
    socket.current = null;
  };

  useEffect(() => {
    const handleResize = () => {
      const w = Math.max(320, document.documentElement.clientWidth);

      const baseSize = w <= 800 ? 39 : 192;
      const fontSizeNum = w / baseSize;

      document.documentElement.style.fontSize = `${fontSizeNum}px`;
    };

    handleResize();
    setLang(formatLang(lang));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sending, loading]);

  useEffect(() => {
    const init = async () => {
      if (token && userProfile && weatherInfo.weather_type) {
        buildSocket({ token, isReconnect: false });
        // displayWeather();

        // data tracking - page view
        pageview({
          bossID: userProfile?.boss_id,
          profileID: userProfile?.extra?.profile_id,
          redirect: userProfile?.device_os,
        });

        const scrollDoc = document.querySelector('.scroll');
        scrollDoc.addEventListener(
          'touchmove',
          (e) => {
            if (scrollDoc?.offsetTop <= 5) {
              hideWeather();
            }
          },
          { useCapture: true },
        );

        window.addEventListener('beforeunload', () => {
          console.log('leave BO page...');

          // 更新APP顶部栏颜色
          updateMenuColor({ color: '#111111', platform: platformInfo?.platformType });
          socketOffline();
        });

        // TODO: should remove
        window.addEventListener('pagehide', () => {
          console.log('window leave BO page(pagehide)...');
          // 更新APP顶部栏颜色
          updateMenuColor({ color: '#111111', platform: platformInfo?.platformType });
          socketOffline();
        });

        window.addEventListener('offline', (e) => {
          if (e?.type === 'offline') {
            socketOffline();
            // message.config({
            //   top:  window.innerHeight / 2 - 132
            // })
            message.error({
              icon: <TipsIcon className="tips-icon" />,
              className: 'toast-tips-content',
              getContainer: () => document.getElementById('root'),
              content: t('network.error'),
              duration: 2,
            });
          }
        });
      }
    };

    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userProfile, weatherInfo]);

  useEffect(() => {
    if (baseInfo?.error) {
      setConnectStatus(ConnectStatusMap.ERROR);
      return;
    }
    if (baseInfo && !introductionRef.current) {
      const id = nanoid(6);
      const time = new Date();
      times.current = { last: times.current.now, now: time };
      setWeatherInfo(baseInfo?.weather || {});

      const introductionId = nanoid();
      currentContainerId.current = introductionId;
      setMessageList((messageList) => [
        ...messageList,
        <div id={introductionId} key={nanoid()}>
          <Message
            message={{
              id,
              type: 'introduction',
              data: baseInfo,
              time,
            }}
            lastTime={times.current.last}
            locker={userLocked.current}
            lockCustomer={({ login, booking }) => setUserLocked({ login, booking })}
          />
        </div>,
      ]);

      // scrollToRow();
      introductionRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseInfo]);

  useEffect(() => {
    if (process.env.CONFIG_SET !== 'production') {
      new VConsole();
    }
  });

  const scrollToRow = (id = 'message-list') => {
    const messageListDoc = document.getElementById(id);

    console.log('height...', messageListDoc.offsetHeight);

    if (messageListDoc) {
      messageListDoc.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  };

  const scrollToTop = (id = 'message-list') => {
    const messageListDoc = document.getElementById(id);

    if (messageListDoc) {
      messageListDoc.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  };

  const onPressEnter = useCallback(
    (text, isCommandModule = true) => {
      console.log({ text });
      const keyword = String(text).trim();
      if (!text || !keyword) {
        return;
      }

      // // TODO: for testing weather
      // const weathers = ['sunny', 'rain', 'clouds', 'wind', 'lightning'];
      // if (weathers.includes(text)) {
      //   setWeatherInfo({ ...weatherInfo, weather_type: text });
      // }

      hideWeather();
      scrollToRow();

      const id = nanoid(10);
      console.log(socketOnline.current, socket);
      if (!socketOnline.current || !socket.current) {
        console.log('socket offlined');
        setConnectStatus(ConnectStatusMap.RESTART);
        waitingQueue.current = [...waitingQueue.current, { id, keyword }];

        buildSocket({ token, isReconnect: true });
      }

      setSending(() => true);
      if (isCommandModule) {
        const time = new Date();
        times.current = { last: times.current.now, now: time };
        inputList.current[id] = time;

        setMessageList((messageList) => [
          ...messageList,
          <Message
            key={nanoid()}
            message={{ id, type: 'keyword', data: { keyword }, time }}
            lastTime={times.current.last}
            sending={true}
            commandSend={() => onPressEnter(keyword)}
            locker={userLocked.current}
            lockCustomer={({ login, booking }) => setUserLocked({ login, booking })}
          />,
        ]);
      }

      setTimeout(() => {
        scrollToRow();
      }, 300);

      if (socketOnline.current && socket.current) {
        socket.current.emit('message', { id, keyword });
      }

      setTimeout(() => {
        waitingQueue.current = R.without([{ id, keyword }], waitingQueue.current);

        setSending(() => false);
      }, timeConstants.COMMAND_SEND_TIMEOUT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socketOnline, token, weatherInfo],
  );

  const onFocusHandler = () => {
    hideWeather();
    scrollToRow();
  };

  const onMenuDislay = () => {
    setTimeout(() => {
      scrollToRow();
    }, 50);
  };

  const Message = ({
    message,
    lastTime,
    sending = false,
    commandSend = () => {},
    locker,
    lockCustomer,
  }) => {
    const { userProfile, platformInfo } = useProfileContext();

    return (
      <div key={nanoid()}>
        <TimeShower last={lastTime} time={message?.time}></TimeShower>
        {message.type === 'introduction' && (
          <Introduction
            data={message.data}
            onFunctionClick={onPressEnter}
            scrollTop={scrollToRow}
          />
        )}
        {message.type === 'keyword' && (
          <CommandCard
            id={message.id}
            command={message?.data?.keyword}
            sendStatus={sending ? 1 : 0}
            errorClick={commandSend}
          />
        )}
        <div className="message-modules">
          {message.type === 'message' &&
            getModules({
              modules: message.data?.module,
              userProfile,
              platformInfo,
              lang,
              onFunctionClick: onPressEnter,
              locker,
              lockCustomer,
              programmeLabels: programmeLabelling.current,
            })}
          <div>
            <Recommend
              recommend={message?.data?.recommend}
              mode={message?.data?.recommend_word_display_style}
              onRecommendClick={onPressEnter}
            />
          </div>
        </div>
      </div>
    );
  };

  const onChatbotBodyTouchStart = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  return (
    <div className="home">
      <HelmetDiv
        title={baseInfo?.introduction?.title}
        metaDescription={baseInfo?.introduction?.description}
        icon={baseInfo?.introduction?.logo}
      />
      <Header
        logoUrl={baseInfo?.introduction?.logo}
        name={baseInfo?.introduction?.title}
        connected={connectStatus !== ConnectStatusMap.ERROR}
        display={showHeader}
        weather={weatherInfo?.weather_type}
        weatherChanged={displayWeather}
      />
      {loginStatus === 2 ? (
        <UnloginCard />
      ) : (
        <div>
          {connectStatus === ConnectStatusMap.ERROR ? (
            <ConnectFailed />
          ) : (
            <ConnectCard
              display={
                loginStatus === 0 || (loginStatus === 1 && connectStatus === ConnectStatusMap.START)
              }
              reconnect={connectStatus === ConnectStatusMap.RESTART}
              done={[ConnectStatusMap.DONE, ConnectStatusMap.ERROR].includes(connectStatus)}
            />
          )}
        </div>
      )}{' '}
      <WeatherCard
        key={weatherInfo?.weather_type || 'weather'}
        docId="weather-card"
        weather={weatherInfo?.weather_type}
      />
      <Fireworks
        visible={fireworksEnable}
        onFinish={() => {
          setFireworksEnable(false);
        }}
      />
      {falldownVisible ? <Falldown imageUrl={falldownImage.current} /> : null}
      <PullLoad
        key={token}
        loadMore={async () => {
          hideWeather();

          const messageListDoc = document.getElementById('message-list');
          if (messageListDoc.getBoundingClientRect().top > 0) {
            console.log('load more ...');
            await getHistoryList();
          }
        }} // 获取更多
        hasMore={true} // 能否获取更多
        threshold={100} // 滚动拉去更多的距离(像素)
        isReverse={true} // 是否调转方向，默认上拉
        height={600}
      >
        <MessageHeader
          id="message-first"
          className="message-first"
          headerInfo={weatherInfo}
          weather={weatherInfo?.weather_type}
          onGooeyClick={onPressEnter}
        />
        <div
          id="loading-icon"
          className={`loading-icon-container ${loading ? 'message-loading' : ''}`}
        >
          <div>
            <RefreshIcon className="loading-icon" />
          </div>
        </div>
        <div id="chat-body" className="chat-body" onTouchStart={onChatbotBodyTouchStart}>
          <div id="message-list" className="message-list">
            <div>{messageList.map((item) => item)}</div>
            <WaitingCard className={`${sending ? '' : 'no-waiting'}`} />
          </div>
          <div id="chat-body-end" />
        </div>
      </PullLoad>
      <Footer
        inputRef={inputRef}
        functions={baseInfo?.functions}
        badgeInfo={baseInfo?.badgeInfo}
        onPressEnter={onPressEnter}
        onFocusHandler={onFocusHandler}
        onMenuDislay={onMenuDislay}
      />
    </div>
  );
};

export default Mobile;
