export const common = {
  braftEditorPlaceHolder: '有問題，問',
  now: '剛剛',
  yesterday: '昨天',
  weeks: {
    sun: '星期日',
    mon: '星期一',
    tue: '星期二',
    wed: '星期三',
    thu: '星期四',
    fri: '星期五',
    sat: '星期六',
  },
  showMore: '顯示更多',
  connecting: '幫緊你！幫緊你！',
  connectedFailed: '連接失敗',
  notConnect: '連唔到啦，試一試刷新？',
  refresh: '刷新',
  send: '發送',
  notLoginLabel: '檢測到你還沒有登錄，先登錄一下？',
  login: '去登錄',
  network: {
    error: '網絡異常',
    offline: '網絡連接已斷開',
  },
  historyFailed: '載入失敗，請稍後重試',
  exit: {
    content: '唔多留一陣嗎？',
    confirm: '殘忍離開',
    cancel: '繼續留低',
  },
};
