const envConfig = {
  env: 'development',
  i18n: {
    debug: true,
    fallbackLng: 'zh-HK',
    defaultNS: 'main',
    fallbackNS: 'main',
    interpolation: {
      escapeValue: false, // not needed for react!! (false)
    },
    react: {
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      useSuspense: true,
    },
    detection: {
      // order and from where user language should be detected
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'language',
    },
  },
  format: {
    date: 'YYYY-MM-DD',
    time: 'HH:mm',
  },
  heartTimeInSecond: 20,
};

module.exports = envConfig;
