import { common } from './common.js';
import { purchase } from './purchase';
import { contact } from './contact.js';
import { weather } from './weather';
import { programme } from './programme';
import { customer } from './customer';

export const enUS = {
  common,
  contact,
  purchase,
  weather,
  programme,
  customer,
};
