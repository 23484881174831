import { Button, Image } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TitleCard from '@/components/titleCard';
import { useProfileContext } from '@/hooks/useProfile';
import { purchaseBtnClick } from '@/utils/gtm';
import './index.less';

const imageRegExp = 'http://img.tvb.com';

const BuyingCard = ({
  title,
  // backgroundUrl,
  items,
}) => {
  const { t } = useTranslation('purchase');
  const { userProfile } = useProfileContext();

  return (
    <div className="buying-card-wrapper fade-in-item">
      <TitleCard title={title} />
      <div className="buying-card">
        <div className="sub-module">
          {items.map((data, dataIndex) => {
            const onBuyingClick = () => {
              // data tracking
              purchaseBtnClick({
                bossID: userProfile?.boss_id,
                profileID: userProfile?.extra?.profile_id,
                campaignCode: data.id,
                redirect: userProfile?.device_os,
              });

              console.log('buying deeplink', data.deeplink);

              return data.deeplink && window.open(data.deeplink, '_self');
            };

            const imageUrl = data.image
              ? data.image.replace(imageRegExp, 'https://img.tvb.com')
              : '';

            return data.image ? (
              <div
                key={dataIndex}
                className="image-sub-module-content"
                style={{ backgroundImage: `url(${imageUrl})`, backgroundColor: '#ffffff' }}
              >
                <Image src={imageUrl} preview={false} />
                <div className="image-module-description">
                  <span>{data.description}</span>
                  <span>
                    <Button
                      className="sub-module-button image-sub-module-button"
                      onClick={onBuyingClick}
                    >
                      {data.button || t('buyingNow')}
                    </Button>
                  </span>
                </div>
              </div>
            ) : (
              <div key={dataIndex} className="content-sub-module-content">
                <div className="content-module-description">
                  <span>{data.description}</span>
                  <span>
                    <Button className="sub-module-content-button" onClick={onBuyingClick}>
                      <span>
                        <span>{`${t('onlyPrice')} HK$ ${data.price}`}</span>
                        <div className="arrow"></div>
                      </span>
                    </Button>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BuyingCard;
