import React from 'react';
import { Col, Row } from 'antd';

import IconCard from '@/components/iconCard';
import { useProfileContext } from '@/hooks/useProfile';
import { getDeeplinkFromAppVersion } from '@/utils/deeplink';

import './index.less';

const FuncMenu = ({ functions = [], badge, clickHandler = () => {} }) => {
  const { platformInfo } = useProfileContext();
  const funcGroups = functions.reduce((acc, func, index) => {
    // TODO: other client, eg: web, TV...
    if (index % 4 === 0) {
      return [...acc, [func]];
    }
    const lastOne = acc.pop();

    return [...acc, [...lastOne, func]];
  }, []);

  return (
    <div className="func-menu-page">
      {funcGroups.map((items, index) => (
        <Row key={index}>
          {items.map((func, funcIndex) => {
            const href = platformInfo.isApp
              ? getDeeplinkFromAppVersion({ deeplink: func.deep_link, platform: platformInfo })
              : func.website_url;

            console.log('Function href', href);

            return (
              <Col span={6} key={funcIndex}>
                <IconCard
                  refId={func.id}
                  refData={badge?.info?.[func.id]}
                  imageUrl={func?.icon_url}
                  summary={func.title}
                  onClick={() => clickHandler(func.is_keyword === 1 ? func.title : '')}
                  {...(func.is_keyword !== 1 && { href })}
                />
              </Col>
            );
          })}
        </Row>
      ))}
    </div>
  );
};

export default FuncMenu;
