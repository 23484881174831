import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as AudioIcon } from '@/assets/svg/icon_audio.svg';
import './index.less';

const AudioPlayer = ({ className, audioUrl }) => {
  const [playing, setPlaying] = useState(false);
  const audioRef = useRef();
  const playerClicked = useRef(false);

  useEffect(() => {
    const audioInstance = audioRef.current;
    if (audioInstance) {
      audioInstance.addEventListener('play', (v) => {
        const audios = document.getElementsByTagName('audio');
        if (audios && audios.length > 1) {
          for (let index = 0; index < audios.length; index++) {
            const instance = audios[index];
            if (instance !== audioInstance) {
              instance.pause();
            }
          }
        }

        const videos = document.getElementsByTagName('video');
        if (videos && videos.length > 1) {
          for (let index = 0; index < videos.length; index++) {
            const instance = videos[index];
            instance.pause();
          }
        }

        startPlay();
      });

      audioInstance.addEventListener('playing', (v) => {
        startPlay();
      });

      audioInstance.addEventListener('pause', (v) => {
        stopPlay();
      });

      audioInstance.addEventListener('ended', (v) => {
        stopPlay();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startPlay = () => {
    setPlaying(true);
  };

  const stopPlay = () => {
    setPlaying(false);
  };

  const onPlayerClick = () => {
    // 不能再点击
    if (playerClicked.current) {
      return;
    }

    playerClicked.current = true;
    if (!playing) {
      if (!audioRef.current.src) {
        audioRef.current.src = audioUrl;
      }

      audioRef.current.play().catch((e) => {
        console.error('Play audio failed', e);
      });
    } else {
      audioRef.current.pause();
    }

    setTimeout(() => {
      playerClicked.current = false;
    }, 100);
  };

  return (
    <div className={`audio-player ${className}`} onClick={onPlayerClick}>
      {playing ? <div className="audio-playing"></div> : <AudioIcon className="audio-icon" />}
      <audio ref={audioRef} proload="none"></audio>
    </div>
  );
};

export default AudioPlayer;
