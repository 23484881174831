import getClient from '../utils/clientUtil';
import config from '@/config';

const { retryAxios } = getClient({ baseURL: config.session.baseUrl, withCredentials: true });

export const getToken = () => retryAxios.get(config.session.path);

export const getTokenByFetch = async () => {
  const fetchResult = await fetch(`${config.session.baseUrl}${config.session.path}`, {
    credentials: 'include',
  });

  const result = await fetchResult.json();
  return result;
};
