import ua from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

const SUPER_APP_REG_EXP = new RegExp('SuperApp', 'i');
// eslint-disable-next-line no-useless-escape
const APP_VERSION_EXP = new RegExp(/myTVSUPER\/([\w|\.]+)/, 'i');

// X-App-Client UUID
const requestClientIdUUID = uuidv4();

const getPlatform = () => {
  const agent = ua(window.navigator.userAgent);

  if (agent.os.name === 'iOS') {
    return 'ios';
  } else if (agent.os.name === 'Android') {
    return 'android';
  } else {
    return 'web';
  }
};

export const getTitle = () => {
  return document.title?.split('-')?.join('')?.toLowerCase() || 'chatbot';
};

export const getPlatformInfo = () => {
  const agent = ua(window.navigator.userAgent);

  const isApp = SUPER_APP_REG_EXP.test(agent?.ua);
  const platformType = getPlatform();
  const appMatch = agent?.ua?.match(APP_VERSION_EXP);
  const appBuilder = appMatch ? appMatch[0]?.split('/') : null;
  const appVersion = appBuilder ? appBuilder[1] : null;

  return { ...agent, platformType, isApp, appVersion };
};

export const getPlatformHeader = ({ lang }) => {
  const platform = getPlatformInfo();
  const title = getTitle();

  // TODO: need TV
  const requestClientId = `${title}-${platform.isApp ? 'app' : 'h5'}/${lang}/${
    platform.appVersion || '1.0.0'
  }/${requestClientIdUUID}`;

  console.log({ requestClientId });

  return requestClientId;
};

export default getPlatform;
