import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ConfigProvider } from 'antd';
import localeHK from 'antd/lib/locale-provider/zh_HK';

import 'antd/dist/antd.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from '@/i18n';
import { ConfigContextProvider } from '@/hooks/useConfig';
import { AuthContextProvider } from '@/hooks/useAuth';
import { ProfileContextProvider } from '@/hooks/useProfile';

ReactDOM.render(
  <BrowserRouter basename="/">
    <ConfigProvider locale={localeHK} autoInsertSpaceInButton={false}>
      <I18nextProvider i18n={i18n}>
        <ConfigContextProvider>
          <AuthContextProvider>
            <ProfileContextProvider>
              <App />
            </ProfileContextProvider>
          </AuthContextProvider>
        </ConfigContextProvider>
      </I18nextProvider>
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
