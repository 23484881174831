import config from '@/config';
import { getTitle } from './platform';

const LANG_REGEXP = new RegExp('{lang}', 'gi');

// 将APP version转成小数形式，如v4.4.1 => 4.4
const getAppVersion = (platform) => {
  const appVersionInArray = platform.appVersion?.split('.');
  const appVersion = platform.appVersion
    ? Number(appVersionInArray[0]) + 0.1 * Number(appVersionInArray[1])
    : null;

  return appVersion;
};

// 獲取版本號數字類型
const getAppVersionInNumber = (appVersion) => {
  if (!appVersion) {
    return 0;
  }
  const appVersionInArray = appVersion?.split('.');
  const version = appVersion
    ? Number(appVersionInArray[0]) * 1000 +
      (appVersionInArray[1] ? Number(appVersionInArray[1]) : 0) +
      (appVersionInArray[2] ? 0.001 * Number(appVersionInArray[2]) : 0)
    : null;

  return version;
};

const getDeeplinkObj = (deeplink) => {
  if (!deeplink) {
    return {};
  }

  try {
    return JSON.parse(deeplink);
  } catch (e) {
    // 匹配旧数据
    return { 0: deeplink };
  }
};

export const replaceLang = ({ deeplink = '', lang = 'tc' }) => {
  return deeplink?.replace(LANG_REGEXP, lang);
};

export const getProgrammeDeeplink = ({ lang, programme, platform }) => {
  const source =
    platform.platformType === 'ios'
      ? 'myTVSUPERiOS'
      : platform.platformType === 'android'
      ? 'myTVSUPERAndroid'
      : null;

  const appVersion = getAppVersion(platform);

  // app v4.3 v4.4
  const programUrlLegacy = `${config.programme.baseUrl}/${lang}/${config.programme.universal}/${
    programme.path
  }/${
    programme.title
  }?utm_source=${source}&utm_medium=Chatbot&utm_campaign=${getTitle().toUpperCase()}CHSubscription`;

  // app v4.5及以后
  const programUrlNow = `${config.session.baseUrl}/${lang}/${config.programme.universal}/${
    programme.path
  }?utm_source=${source}&utm_medium=Chatbot&utm_campaign=${getTitle().toUpperCase()}CHSubscription`;

  const programUrl = appVersion && appVersion < 4.5 ? programUrlLegacy : programUrlNow;
  const redirectLink = platform.isApp
    ? `${config.host}/${lang}?appAction=deeplink&target=${programUrl}`
    : programUrl;

  return redirectLink;
};

export const getOfferGroupDeeplink = ({ lang, offerGroup, platform }) => {
  const appVersion = getAppVersion(platform);

  // app v4.3 v4.4
  const offerGroupUrlLegacy = `${config.cs.baseUrl}/${lang}/memberzone/newSubscription/campaignListing/${offerGroup.id}?type=upselling&from=viewing`;

  // app v4.5及以后
  const offerGroupUrlNow = `${config.session.baseUrl}/${lang}/account/subscription/?offer_group_code=${offerGroup.id}`;

  const programUrl = appVersion && appVersion < 4.5 ? offerGroupUrlLegacy : offerGroupUrlNow;
  const redirectLink = platform.isApp
    ? `${config.host}/${lang}?appAction=deeplink&target=${programUrl}`
    : programUrl;

  return redirectLink;
};

export const getNormalOfferDeeplink = ({ lang, normalOffer, platform }) => {
  const appVersion = getAppVersion(platform);

  // app v4.3 v4.4
  const normalOfferUrlLegacy = `${config.cs.baseUrl}/${lang}/memberzone/newSubscription/campaignListing?code=${normalOffer.code}`;

  // app v4.5及以后
  const normalOfferUrlNow = `${config.session.baseUrl}/${lang}/account/subscription/?campaign_code=${normalOffer.code}`;

  const programUrl = appVersion && appVersion < 4.5 ? normalOfferUrlLegacy : normalOfferUrlNow;
  const redirectLink = platform.isApp
    ? `${config.host}/${lang}?appAction=deeplink&target=${programUrl}`
    : programUrl;

  return redirectLink;
};

export const getDeeplinkFromAppVersion = ({ deeplink, platform }) => {
  if (!deeplink) {
    return '';
  }

  const deeplinks = getDeeplinkObj(deeplink);

  let appVersions = {};
  Object.keys(deeplinks).forEach((item) => {
    const version = getAppVersionInNumber(item);
    appVersions = { ...appVersions, [version]: item };
  });
  const currentVersion = getAppVersionInNumber(platform.appVersion);

  const versions = [...Object.keys(appVersions).map(Number), Number(currentVersion)].sort();
  const versionIndex = versions.indexOf(currentVersion);
  const version =
    currentVersion !== versions[versionIndex + 1]
      ? appVersions[versions[versionIndex - 1]]
      : appVersions[versions[versionIndex + 1]];

  return deeplinks[version];
};
