// @flow strict
import * as R from 'ramda';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { getSystemConfig } from '@/client/chabot';
import { sendExceptions } from './utils/exception';

const configContext = createContext({ config: {} });

export const ConfigContextProvider = ({ children }) => {
  const [config, setConfig] = useState({});

  useEffect(() => {
    const loadConfig = async () => {
      if (R.isEmpty(config)) {
        try {
          const result = await getSystemConfig();

          setConfig(result?.data || {});
        } catch (err) {
          console.log('Get system config failed with error', err);

          sendExceptions({
            token: '',
            langPrefix: 'tc',
            errorType: 'error',
            error: 'Get system config error' + err.message,
          });
          return;
        }
      }
    };

    loadConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <configContext.Provider value={{ config }}>{children}</configContext.Provider>;
};

export const useConfigContext = () => useContext(configContext);
