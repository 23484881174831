import React from 'react';

import './index.scss';

const WaitingCard = ({ className }) => {
  return (
    <div className={`waiting-card ${className} fade-in-item`}>
      <div className="LoaderBalls">
        <span className="LoaderBalls__item"></span>
        <span className="LoaderBalls__item"></span>
        <span className="LoaderBalls__item"></span>
      </div>
    </div>
  );
};

export default WaitingCard;
