import config from '@/config';

import { getTitle } from './platform';

const PRODUCT_NAME = 'MyTV Super Chatbot';
const APP_TYPE = 'in app browser';

export const GTM_ID = config.gtmId;

/** 页面 */
export const pageview = (otherParams = {}) => {
  window.dataLayer.push({
    productName: PRODUCT_NAME,
    appType: APP_TYPE,
    event: 'scnOpen',
    'gtm.start': new Date().getTime(),
    scnName: `${getTitle()}/MainPage`,
    ...otherParams,
  });
};

/** function */
export const functionBtnClick = ({
  screenName = `${getTitle()}/MainPage`,
  funcName,
  ...params
}) => {
  const btnParams = {
    resId: 'function',
    type: getTitle(),
    screenName,
    label: funcName,
    ...params,
  };

  btnClick(btnParams);
};

/** 购买服务按钮 */
export const purchaseBtnClick = ({
  screenName = `${getTitle()}/MainPage`,
  campaignCode,
  ...params
}) => {
  const btnParams = {
    resId: 'purchase',
    type: getTitle(),
    screenName,
    ID: campaignCode,
    ...params,
  };

  btnClick(btnParams);
};

/** 剧集program点击 */
export const programBtnClick = ({
  screenName = `${getTitle()}/MainPage`,
  programId,
  bannerId,
  platform,
  ...params
}) => {
  const source =
    platform === 'ios' ? 'myTVSUPERiOS' : platform === 'android' ? 'myTVSUPERAndroid' : null;

  const btnParams = {
    resId: 'program',
    type: getTitle(),
    screenName,
    label: bannerId,
    programID: programId,
    source: source,
    medium: 'Chatbot',
    campaign: `${getTitle()?.toUpperCase()}CHSubscription`,
    ...params,
  };

  btnClick(btnParams);
};

export const btnClick = ({ screenName, resId, type, ...params }) => {
  window.dataLayer.push({
    productName: PRODUCT_NAME,
    appType: APP_TYPE,
    event: 'btnClick',
    resID: resId,
    type,
    'gtm.start': new Date().getTime(),
    scnName: screenName,
    ...params,
  });
};

export const baseTracking = (params) => {
  window.dataLayer.unshift({
    productName: PRODUCT_NAME,
    appType: APP_TYPE,
    ...params,
  });
};
