import './index.less';

import * as R from 'ramda';
import { nanoid } from 'nanoid';

import TextModule from '@/components/textModule';
import SwiperContainer from '@/components/swiperContainer';
import ImageCard from '@/components/imageCard';
import ButtonCard from '@/components/buttonCard';
import BuyingCard from '@/components/buyingCard';
import VideoCard from '@/components/videoCard';
import IconCard from '@/components/iconCard';
import ProgrammeCard from '@/components/programmeCard';
import ContactCard from '@/components/contactCard';
import CustomerProfile from '@/components/customerProfile';
import DokodemoDoor from './dokodemoDoor';
import { programBtnClick } from '@/utils/gtm';
import { getDeeplinkFromAppVersion } from '@/utils/deeplink';
import {
  replaceLang,
  getProgrammeDeeplink,
  getNormalOfferDeeplink,
  getOfferGroupDeeplink,
} from '@/utils/deeplink';

const templateTypes = [
  'text',
  'button',
  'link',
  'program',
  'function',
  'contact',
  'programme_schedule',
  'customer_profile',
  'dokodemo_door',
];

const PROGRAMME_FREE_EPISODE_FIELD = 'free_episode';
const PROGRAMME_FREE_EPISODE_FIELD_RECOM = 'recom_free_episode';

export const getTemplate = ({
  data,
  userProfile,
  onFunctionClick,
  platformInfo,
  lang,
  locker,
  lockCustomer,
  programmeLabels = {},
}) => {
  const { tpl_id: tplId, api_data: apiData = [], template_data: templateData = {} } = data;

  if (!templateTypes.includes(tplId)) {
    return null;
  }

  if (templateData.text) {
    const text = templateData.text;
    const words = text.description || text.description_zh;
    const audioUrl = R.path(['audio', 0, 'url'], text) || R.path(['audio_zh', 0, 'url'], text);

    return { type: 'text', template: <TextModule key={nanoid()} words={words} audio={audioUrl} /> };
  }

  if (templateData.button) {
    // tvLink, website
    const { button, button_zh, deeplink, website } = templateData.button;

    const href = platformInfo.isApp
      ? getDeeplinkFromAppVersion({ deeplink: deeplink, platform: platformInfo })
      : website;
    console.log('button template href', href);

    return {
      type: 'button',
      template: (
        <div key={nanoid()} className="button-module">
          <div className="fade-in-item">
            <ButtonCard label={button || button_zh} url={replaceLang({ deeplink: href, lang })} />
          </div>
        </div>
      ),
    };
  }

  if (templateData.function) {
    if (R.isEmpty(R.pathOr({}, [0], templateData.function))) {
      return null;
    }

    const functions = templateData.function.reduce(
      (sum, item) =>
        // TODO: check other clients
        (platformInfo.isApp && item.is_app_client === 1) || !platformInfo.isApp
          ? [...sum, item]
          : sum,
      [],
    );

    return {
      type: 'function',
      template: (
        <div key={nanoid()}>
          <SwiperContainer
            slideClassName={'function-swiper-slide-item'}
            items={functions.map((item, index) => {
              const title = item.title || item.title_zh;
              const href = platformInfo.isApp
                ? getDeeplinkFromAppVersion({ deeplink: item.deep_link, platform: platformInfo })
                : item.website_url;

              console.log('Function template href', href);
              return (
                <IconCard
                  key={index}
                  imageUrl={item?.icon_url}
                  summary={title}
                  {...(item.is_keyword === 1
                    ? { onClick: () => onFunctionClick(title) }
                    : { href })}
                />
              );
            })}
          />
        </div>
      ),
    };
  }

  if (templateData.contact) {
    const {
      button,
      button_zh,
      deeplink,
      website,
      description,
      description_zh,
      email,
      phone,
      whats_app: whatsApp,
      // website,tvLink
      remark,
      remark_zh,
    } = templateData.contact;

    const href = platformInfo.isApp
      ? getDeeplinkFromAppVersion({ deeplink: deeplink, platform: platformInfo })
      : website;
    console.log('Contact deeplink', platformInfo.isApp, href);

    return {
      type: 'contact',
      template: (
        <ContactCard
          key={nanoid()}
          contacts={{
            deeplink: replaceLang({ deeplink: href, lang }),
            email,
            phone,
            whatsApp,
            button: button || button_zh,
            remarks: remark || remark_zh,
            description: description || description_zh,
          }}
        />
      ),
    };
  }

  if (templateData.program) {
    if (!apiData || R.isEmpty(apiData)) {
      return null;
    }

    const apiType = apiData.data.api_type;
    const programData = apiData.data[apiType];
    const canPlay = R.path([0, 'items', 0, 'can_play'], programData);
    const programmes = programData
      .map((program) => {
        if (!program?.items || R.isEmpty(program.items)) {
          return null;
        }

        const title = program.title || apiData.title || apiData.title_zh;
        const items = program.items.map((item) => {
          const {
            path,
            path_zh,
            ref,
            programmeId: originProgrammeId,
            programme_id,
            image,
            image_zh,
            title,
            title_zh,
            labelling_group: labellingGroup = [],
          } = item;

          const videoTitle = title || title_zh;
          const imageUrl = R.path(['landscape_small'], image || image_zh);
          const video = {
            path: path || path_zh,
            image: imageUrl,
            title: canPlay ? videoTitle : videoTitle?.replace(' ', '-') || '-',
          };
          const programmeId = programme_id || originProgrammeId || ref;

          const redirectLink = getProgrammeDeeplink({
            lang,
            programme: video,
            platform: platformInfo,
          });

          const programClickHandler = () => {
            console.log('program deeplink', redirectLink);

            // data tracking
            programBtnClick({
              bossID: userProfile?.boss_id,
              profileID: userProfile?.extra?.profile_id,
              programId: programmeId,
              label: program.title || program.title_zh,
              ID: program?.programme_name?.tc || videoTitle,
              redirect: userProfile?.device_os,
              platform: platformInfo.platformType,
            });

            window.open(redirectLink, '_self');
          };

          return {
            video,
            programmeId,
            programClickHandler,
            labellingGroup,
          };
        });

        return { title, items };
      })
      .filter((item) => !!item);

    return programmes.map(({ title, items }) => ({
      type: 'program',
      name: templateData.program,
      template: (
        <SwiperContainer
          key={nanoid()}
          title={title}
          slideClassName={canPlay ? 'program-video-swiper-slide-item' : 'program-swiper-slide-item'}
          items={items.map((item, apiDataIndex) => {
            const { video, programClickHandler, labellingGroup = [] } = item;
            const currentUiDisplayLevel = userProfile?.customer?.current_ui_display_level;
            const freeEpisodeLabelling =
              currentUiDisplayLevel !== 1 && labellingGroup
                ? labellingGroup
                    .reduce((p, c) => {
                      if (
                        [PROGRAMME_FREE_EPISODE_FIELD, PROGRAMME_FREE_EPISODE_FIELD_RECOM].includes(
                          c.type,
                        )
                      )
                        p.push(c.value);
                      return p;
                    }, [])
                    .sort()[labellingGroup.length - 1]
                : 0;
            const freeEpisode =
              currentUiDisplayLevel !== 1 ? programmeLabels[item.programmeId] || 0 : 0;

            return canPlay ? (
              <VideoCard
                key={apiDataIndex}
                className={'video-card-view'}
                videoUrl={video.path}
                poster={video.image}
                summary={video.title}
                playsinline={false}
                pauseReload={true}
              />
            ) : (
              <div key={apiDataIndex} onClick={programClickHandler}>
                <ImageCard
                  imageUrl={video.image}
                  summary={video.title}
                  freeEpisode={Math.max(freeEpisodeLabelling || 0, freeEpisode)}
                />
              </div>
            );
          })}
        />
      ),
    }));
  }

  if (templateData.link) {
    if (!apiData || R.isEmpty(apiData)) {
      return null;
    }

    const apiType = apiData.data.api_type;
    const buyingData = apiData.data[apiType];
    const { normalOffer = [], offerGroups = [] } = buyingData || {};

    const offerData = [
      ...normalOffer.map((item) => ({
        ...item,
        type: 'normaloffer',
        deeplink: getNormalOfferDeeplink({
          lang,
          normalOffer: item,
          platform: platformInfo,
        }),
      })),
      ...offerGroups.map((item) => ({
        ...item,
        type: 'offergroup',
        deeplink: getOfferGroupDeeplink({
          lang,
          offerGroup: item,
          platform: platformInfo,
        }),
      })),
    ];

    const subList = offerData.map((i) => {
      // use normal offer code as compaignId
      const offerId = i.type === 'offergroup' ? i.id : i.code;

      return {
        id: offerId,
        description: i.title,
        image: i?.mobileImage?.mobile_image_04,
        price: i.amount,
        deeplink: i.deeplink,
        button: templateData.link?.button || templateData.link?.button_zh,
      };
    });

    return {
      type: 'link',
      template: (
        <BuyingCard
          title={templateData.link?.title || templateData.link?.title_zh}
          items={subList}
          key={nanoid()}
        ></BuyingCard>
      ),
    };
  }

  if (templateData.programme_schedule) {
    const apiType = apiData.data.api_type;
    const programSchedules = apiData.data[apiType];

    return {
      type: 'programme_schedule',
      template: (
        <div className="programme-schedule" key={nanoid()}>
          <SwiperContainer
            key={nanoid()}
            title={apiData.title || apiData.title_zh}
            slideClassName={'program-schedule-swiper-slide'}
            items={programSchedules.map((item, index) => {
              return (
                <ProgrammeCard key={index} programme={item} programmeLabels={programmeLabels} />
              );
            })}
          />
        </div>
      ),
    };
  }

  if (templateData.customer_profile) {
    if (!apiData || R.isEmpty(apiData)) {
      return null;
    }

    const profileTilte = apiData.title;
    const customerProfile = apiData.data.customer_profile.customer;

    return {
      type: 'customer_profile',
      template: (
        <CustomerProfile
          locker={locker}
          lockCustomer={lockCustomer}
          title={profileTilte}
          customer={customerProfile}
          key={nanoid()}
        ></CustomerProfile>
      ),
    };
  }

  if (templateData.dokodemo_door) {
    if (!apiData || R.isEmpty(apiData)) {
      return null;
    }

    const dokodemoDoor = apiData.data.dokodemo_door;
    return {
      type: 'dokodemo_door',
      template: <DokodemoDoor data={dokodemoDoor} key={nanoid()}></DokodemoDoor>,
    };
  }
};
