import { nanoid } from 'nanoid';

const SOCKET_TIMEOUT = 10000;
export const initSocket = async ({
  url,
  lang,
  token,
  clientToken,
  heartTimeInSecond,
  mzToken,
  isApp,
}) => {
  console.log('socket tokens', { token, clientToken, mzToken, isApp });
  if (!url || !token) {
    return null;
  }

  try {
    const client = await getInstanceInTry({
      url,
      lang,
      token,
      clientToken,
      heartTime: heartTimeInSecond * 1000,
      mzToken,
      isApp,
    });

    window.addEventListener('offline', () => {
      client.close();
    });

    return client;
  } catch (error) {
    console.error('Connect error', error);
    throw error;
  }
};

const getInstanceInTry = ({ url, lang, token, clientToken, heartTime, mzToken, isApp }) => {
  return new Promise(function (resolve, reject) {
    let count = 0;
    const errorMessage = [];
    console.log('connect socket...');

    getInstance({ id: 0, url, lang, token, clientToken, heartTime, mzToken, isApp }, () =>
      clearInterval(interval),
    )
      .then((instance) => resolve(instance))
      .catch((error) => {
        console.log('connect WS', error.message);
        errorMessage.push('connect WS' + error.message);
      });

    count++;
    const maxTryTimes = 3;
    const interval = setInterval(() => {
      if (count >= maxTryTimes) {
        clearInterval(interval);
        return reject(
          new Error('WS connect failed - ' + errorMessage ?? 'All connection trying no answer'),
        );
      }

      getInstance({ id: count, url, lang, token, clientToken, heartTime, mzToken, isApp }, () =>
        clearInterval(interval),
      )
        .then((instance) => resolve(instance))
        .catch((error) => {
          console.log('reconnect WS ', error.message);
          errorMessage.push('reconnect WS ' + error.message);
        });

      count++;
    }, SOCKET_TIMEOUT + 100);
  });
};

const getInstance = (
  { id, url, lang, token, clientToken = null, heartTime, mzToken = null, isApp },
  callback,
) => {
  return new Promise(function (resolve, reject) {
    let timeout = 0;
    try {
      const client = new WebSocket(url, [
        'authorization',
        token,
        clientToken,
        lang,
        isApp ? 'app' : 'h5',
        mzToken,
      ]);
      client.id = id;
      client.nanoid = nanoid(8);

      // 建立连接超时
      timeout = setTimeout(() => {
        console.log('socket client timeout');

        reject(new Error('socket client timeout'));
      }, SOCKET_TIMEOUT);

      client.onopen = () => {
        console.log('onopen......', { id, nanoid: client.nanoid }, new Date());
        // 心跳
        heartBeat(client, heartTime);

        // 增加onconnect事件方法
        if (typeof client?.onconnect === 'function') {
          client?.onconnect();
        }

        client.emit = (event, message) => {
          const { id, ...others } = message;
          client.send(
            JSON.stringify({
              id: id || nanoid(8),
              event,
              payload: others,
            }),
          );
        };

        clearTimeout(timeout);
        callback && callback();
        resolve(client);
      };

      client.onclose = (e) => {
        console.log('WS client close', e);
        clearTimeout(timeout);

        reject(new Error('client close', { code: e.code, reason: e.reason, wasClean: e.wasClean }));
      };

      client.onerror = (e) => {
        console.log('WS client error', { id, nanoid: client.nanoid }, e);
        clearTimeout(timeout);
        client.close();

        reject(new Error('client error ' + e?.target?.url));
      };
    } catch (e) {
      clearTimeout(timeout);
      reject(e);
    }
  });
};

const heartBeat = (wsClient, heartTime) => {
  console.log('heartBeat start...', { id: wsClient.id, nanoid: wsClient.nanoid });
  const stopHeart = () => {
    console.log('heartBeat stoped...', { id: wsClient.id, nanoid: wsClient.nanoid });
    clearInterval(interval);
  };
  wsClient.stopHeart = stopHeart;

  const interval = setInterval(() => {
    console.log('heartBeat status', wsClient.readyState, wsClient.id, wsClient.nanoid);
    if (wsClient.readyState === 1) {
      wsClient.send(
        JSON.stringify({
          event: 'ping', // 心跳事件：ping
        }),
      );
    } else {
      stopHeart();
    }
  }, heartTime);
};
