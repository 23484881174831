export const contact = {
  email: '電郵',
  phone: '服務熱線',
  whatsApp: 'WhatsApp',
  contactConfirm: '確認與客戶服務主任進行網上即時對話或留言？',
  callConfirm: '即刻撥打客戶服務熱線？',
  cancelText: '取消',
  confirmText: '確認',
  callText: '撥打',
  copied: '複製成功!',
};
