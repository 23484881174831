import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Image, Space, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FromPlayIcon } from '@/assets/svg/icon_from_play.svg';
import { useProfileContext } from '@/hooks/useProfile';
import { programBtnClick } from '@/utils/gtm';
import { getImageUrlToTls } from '@/utils/imageUtil';
import { getProgrammeDeeplink } from '@/utils/deeplink';
import './index.less';

/**
 *
 * @param {*} programme
 *    id: string
 *    title: string
 *    image: Url
 *    start_time: string
 *    end_time: string
 *    description: string
 *
 * @returns
 */
const ProgrammeItemCard = ({ id, programme = {}, handleMoreTags, showAllTags = false }) => {
  const { userProfile, lang, platformInfo } = useProfileContext();
  const { programmeId, ref, name_en, name_tc, path, image = {}, programme_name, tags } = programme;
  const categoryTags = tags
    .filter((tag) => tag.type === 'category' || tag.type === 'sub_category')
    .map((tag) => {
      return lang === 'en' ? tag?.name_en : tag?.name_tc;
    });
  const prodYearTag = tags.find((tag) => tag.type === 'prod_year');

  const [imageStatus, setImageStatus] = useState(true);
  const { t } = useTranslation('programme');
  const detailRef = useRef(null);
  const tagsRef = useRef(null);
  const limitWidthTagRef = useRef(null);
  const tagNewLineRef = useRef([]);
  const [hideIndex, setHideIndex] = useState(-1);
  const [hide, setHide] = useState(true);
  const [newHideTag, setNewHideTag] = useState(false);

  const name = lang === 'en' ? name_en : name_tc;
  const redirectLink = getProgrammeDeeplink({
    lang,
    programme: {
      path,
      title: lang === 'tc' ? name?.trim()?.replace(' ', '-') : name?.replace(' ', '-'),
    },
    platform: platformInfo,
  });
  const prodYear = lang === 'tc' ? prodYearTag?.name_en : prodYearTag?.name_tc;

  const onClick = () => {
    console.log('program schedule deeplink', redirectLink);

    programBtnClick({
      bossID: userProfile?.boss_id,
      profileID: userProfile?.extra?.profile_id,
      programId: programmeId || ref,
      ID: programme_name?.tc || name,
      label: '隨意門',
      redirect: userProfile?.device_os,
      platform: platformInfo.platformType,
    });

    window.open(redirectLink, '_self');
  };

  useEffect(() => {
    const maxWidth = detailRef.current.clientWidth;
    let lineWidth = 0;
    if (tagsRef.current?.children?.[0]?.children?.length > 0) {
      for (let index = 0; index < tagsRef.current?.children?.[0]?.children?.length; index++) {
        const item = tagsRef.current?.children?.[0]?.children[index];

        // 换行tag的index
        if (lineWidth + item.clientWidth > maxWidth) {
          tagNewLineRef.current.push(index);

          if (tagNewLineRef.current.length === 2) {
            // 若第二行剩余空间足够放下...，则直接加一个...tag
            if (maxWidth - lineWidth > 40) {
              setNewHideTag(true);
            } else {
              const lastItem = tagsRef.current?.children?.[0]?.children[index - 1];
              // 如果要替换的tag宽度大于...tag和下一个tag之和，则不替换tag,并限制要替换tag的宽度
              if (maxWidth - (lineWidth - lastItem.clientWidth - 5) >= 40 + 5 + item.clientWidth) {
                const maxWidthValue = maxWidth - (lineWidth - lastItem.clientWidth) - 40 - 5;
                lastItem.style.maxWidth = `${maxWidthValue}px`;
                limitWidthTagRef.current = {
                  index: index - 1,
                  maxWidth: `${maxWidthValue}px`,
                };
                setNewHideTag(true);
              }
            }
          }
          // 新的一行
          lineWidth = item.clientWidth + 5;
        } else {
          lineWidth = lineWidth + item.clientWidth + 5;
        }
      }
      // 隐藏更多tag 按钮位置
      if (
        tagNewLineRef.current.length >= 2 &&
        tagsRef.current?.children?.[0]?.children?.length > tagNewLineRef.current[1]
      ) {
        setHideIndex(tagNewLineRef.current[1] - 1);
      }
    }

    if (showAllTags) {
      showMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const showMore = (e) => {
    if (handleMoreTags) {
      handleMoreTags();
    } else {
      setHide(false);
      const maxWidth = detailRef.current.clientWidth;
      // 展开后最后一行的宽度
      // 如果最后一行有多个tag,且宽度加上icon的宽度超出范围，则最后一个tag和icon一起换行
      // 如果最后一行只有一个tag,且宽度加上icon的宽度超出范围，则最后一个tag限制宽度
      if (tagNewLineRef.current.length) {
        let lastLineWidth = 0;
        const lastLineIndex = tagNewLineRef.current[tagNewLineRef.current.length - 1];
        for (
          let index = lastLineIndex;
          index < tagsRef.current?.children?.[0]?.children?.length;
          index++
        ) {
          const item = tagsRef.current?.children?.[0]?.children[index];
          lastLineWidth = lastLineWidth + item.clientWidth + 5;
        }

        // if (!showAllTags) {
        //   lastLineWidth = lastLineWidth - 40;
        // }
        // 展开后最后一行的宽度加上icon宽度超出范围
        if (lastLineWidth + 26 > maxWidth) {
          // 最后一行只有一个tag
          if (
            lastLineIndex ===
            tagsRef.current?.children?.[0]?.children?.length - (newHideTag ? 2 : 1)
          ) {
            tagsRef.current.children[0].children[
              tagsRef.current?.children?.[0]?.children?.length - 1
            ].style.maxWidth = `${maxWidth - 26}px`;
          } else {
            // 最后一行有多个tag
            tagsRef.current.children[0].children[
              tagsRef.current?.children?.[0]?.children?.length - 1
            ].style.marginRight = `${Math.max(lastLineWidth + 26 - maxWidth, 26)}px`;
          }
        }
      }

      if (limitWidthTagRef.current) {
        const item = tagsRef.current?.children?.[0]?.children[limitWidthTagRef.current.index];
        item.style.maxWidth = 'unset';
      }
    }
    e?.stopPropagation();
  };

  const hideMore = (e) => {
    setHide(true);

    if (limitWidthTagRef.current) {
      const item = tagsRef.current?.children?.[0]?.children[limitWidthTagRef.current.index];
      item.style.maxWidth = limitWidthTagRef.current.maxWidth;
    }

    e?.stopPropagation();
  };

  return (
    <div className="programmed-item" id={id} onClick={onClick}>
      {imageStatus ? (
        <Image
          className="portrait-card-image"
          src={getImageUrlToTls(image.portrait_small)}
          preview={false}
          async
          onError={() => {
            setImageStatus(false);
          }}
        />
      ) : (
        <div className="portrait-card-image"></div>
      )}
      <div ref={detailRef} className="programmed-item-detail">
        <p className="programmed-item-detail-title">{name}</p>
        <p className="programmed-item-detail-year">{t('prodYear') + prodYear}</p>
        <div
          className="programmed-item-detail-tags"
          ref={tagsRef}
          style={{ position: hide ? 'absolute' : 'relative' }}
        >
          <Space size={[5, 5]} wrap style={{ maxHeight: hide ? '45px' : 'unset' }}>
            {categoryTags.map((tagName, index) => {
              return hideIndex === index ? (
                newHideTag ? (
                  <Fragment key={index}>
                    <Tag key={index} className="programmed-item-detail-tag">
                      {tagName}
                    </Tag>
                    {hide ? (
                      <Tag
                        key={index + 1000}
                        className={`programmed-item-detail-tag ${
                          hide ? 'programmed-item-detail-tag-hide' : ''
                        }`}
                        onClick={showMore}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </Tag>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                ) : (
                  <Tag
                    key={index}
                    className={`programmed-item-detail-tag ${
                      hide ? 'programmed-item-detail-tag-hide' : ''
                    }`}
                    onClick={showMore}
                  >
                    {hide ? (
                      <>
                        <span></span>
                        <span></span>
                        <span></span>
                      </>
                    ) : (
                      tagName
                    )}
                  </Tag>
                )
              ) : (
                <Tag key={index} className="programmed-item-detail-tag">
                  {tagName}
                </Tag>
              );
            })}
          </Space>
          {hide || tagNewLineRef.current.length < 2 ? (
            <></>
          ) : (
            <div className="programme-tag-hide-icon">
              <FromPlayIcon onClick={hideMore} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgrammeItemCard;
