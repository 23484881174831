import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import config from '@/config';
import { useProfileContext } from '@/hooks/useProfile';

import './index.less';

const UnloginCard = () => {
  const { t } = useTranslation('common');
  const { platformInfo, lang } = useProfileContext();

  const onLogin = () => {
    const url = window.location.href;

    const langPrefix = lang === 'tc' ? 'tc' : 'en';
    const baseLoginUrl = `${config.loginUrl}/${langPrefix}/login`;

    window.location.href = platformInfo.isApp
      ? '?appAction=login'
      : `${baseLoginUrl}?destUrl=${url}`;
  };

  return (
    <div id="connecting-failed" className="connecting-failed">
      <div className="conntect-content">
        <div className="failed-sumary">
          <span>{t('notLoginLabel')}</span>
        </div>
        <Button className="refresh-button" onClick={onLogin}>
          <span>{t('login')}</span>
        </Button>
      </div>
    </div>
  );
};

export default UnloginCard;
