const config = {
  env: 'qa',
  version: '20220701',
  gtmId: 'GTM-NWFVFPJ',
  i18n: {
    debug: true,
  },
  host: 'https://qa-s6bo.mytvsuper.com',
  api: 'https://qa-s6bo.mytvsuper.com/api',
  ws: 'wss://qa-s6bo.mytvsuper.com/api/ws',
  mytvsuper: {
    baseUrl: 'https://qa.mytvsuper.com',
    web: 'result',
    universal: 'programme',
  },
  programme: {
    baseUrl: 'https://qa-programme.mytvsuper.com',
    universal: 'programme',
  },
  session: {
    baseUrl: 'https://qa-www.mytvsuper.com',
    path: '/api/auth/getSession/chatbot/',
  },
  cs: {
    baseUrl: 'https://cs.qa.mytvsuper.com',
  },
  loginUrl: 'https://qa-www.mytvsuper.com/',
  bossClientToken: 'GXKob6WxqRn1yQjXQ3Tk5VCX',
  heartTimeInSecond: 10,
};

module.exports = config;
