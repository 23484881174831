const config = {
  env: 'development',
  version: '20220701',
  gtmId: 'GTM-NWFVFPJ',
  i18n: {
    debug: true,
  },
  host: 'https://10.16.0.233:7001',
  api: 'http://10.16.0.233:7001/api',
  ws: 'wss://10.16.0.233:7002/api/ws',
  mytvsuper: {
    baseUrl: 'https://qa.mytvsuper.com',
    web: 'result',
    universal: 'programme',
  },
  programme: {
    baseUrl: 'https://qa-programme.mytvsuper.com',
    universal: 'programme',
  },
  session: {
    baseUrl: 'https://qa-www.mytvsuper.com',
    path: '/api/auth/getSession/chatbot/',
  },
  cs: {
    baseUrl: 'https://cs.qa.mytvsuper.com',
  },
  loginUrl: 'https://qa-www.mytvsuper.com/',
  devToken:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJib3NzX2lkIjoiNzg1NDE5MTQ1IiwiZGV2aWNlX3Rva2VuIjoiV28ySGRjVFoyMTVWMjlWbURHYVRlTXNtIiwiZGV2aWNlX2lkIjoiWmpkallqWmtZV010TW1Wa1ppMDBOVFUxTFRnNE5ETXRPR1ZpWWpVeVpEVXlORFExIiwiZGV2aWNlX3R5cGUiOiJ3ZWIiLCJkZXZpY2Vfb3MiOiJicm93c2VyIiwiZHJtX2lkIjoiWmpkallqWmtZV010TW1Wa1ppMDBOVFUxTFRnNE5ETXRPR1ZpWWpVeVpEVXlORFExIiwiZXh0cmEiOnsicHJvZmlsZV9pZCI6MX0sImlhdCI6MTY2MzE0NjcwNywiZXhwIjoxNjYzMTUwMzA3fQ.TapKAvBto1cwHOOqfYqn3x7lHMVN3Y2eDyu4PB5uFtXLxG8YOLkGxt7lQfnPQD8F7su7DdaOdc2YZejjpeiZo2IOos0iuyQ_HdVG6lPCFZh8a2bPTUaKPvU1_YwLSgMO5uP6yHjKq-iQBJOBncV9j3UEipOnED5vxyiBKkpMfpyW8SrI1zFDS6hZO-_sngFOnR2-cf3K-0bXUS3UROGNf3c5iNkmPbaYQN1Yijt-h7I_CNdrSGnmVmrf73wEcFlmzfOz-EZFM5QDGv-ZVedsuKQ6YpOobKsUdpdcC_umk5_CRqCldQEdkR2hR_d82BykTyt91-J5UhGsWDRBqNdZ_5a-MOFUWw17Szl0Vu9jsUvx0T0eeT4sJNpQoquKaTM8_1ip-Da_bfMulatyK7zkhCjpn0wJ5Yj-Uu3MFDvkCK1bY3Bd2EXS2y6UnGpw4qwKIrYwa8bJt8jQyHslwYlNNg6Kd57VfhH_F_D3wmivNGo8zdt033z0vLou4WDlS08DEKriOMYt482VYuQqt3KWfmmKNjs-V1LUd-9mIfgFvra1Rf4UnuhI91jS2dJCWXMUN2h6h7yxMx7KA-R22VpvENiIMKOlhFT12ybD60puGEJEnXTz-8DFnOetshMt7EG_bNPnRVsXrOynRNjBAp2zln9xm5z5Mp3kn4ve0LcV6ck',
  bossClientToken: 'GXKob6WxqRn1yQjXQ3Tk5VCX',
  heartTimeInSecond: 20,
};

module.exports = config;
