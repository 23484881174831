import { getI18n } from 'react-i18next';

export function setHtmlPageLang(locale) {
  document.querySelector('html')?.setAttribute('lang', locale);
}

export function setLang(locale) {
  if (locale) {
    const i18n = getI18n();
    i18n.changeLanguage(locale);

    setHtmlPageLang(locale);
  } else {
    return;
  }
}

export function getLang() {
  const i18n = getI18n();

  return i18n.language || 'zh-HK';
}

export function formatLang(lang) {
  const lowerLang = String(lang).toLowerCase();

  if (['tc', 'zh-hk', 'tc'].includes(lowerLang)) {
    return 'zh-HK';
  }

  if (['en', 'eng', 'en-us', 'en-hk', 'en-uk'].includes(lowerLang)) {
    return 'en-US';
  }

  return 'zh-HK';
}

export function simplifyLang(lang) {
  const lowerLang = String(lang).toLowerCase();

  if (['tc', 'zh-hk', 'tc'].includes(lowerLang)) {
    return 'tc';
  }

  if (['en', 'eng', 'en-us', 'en-hk', 'en-uk'].includes(lowerLang)) {
    return 'en';
  }

  return 'tc';
}
