import React, { useState } from 'react';
import { Image } from 'antd';
// import Image from '@/components/loadImage';
import { useTranslation } from 'react-i18next';

import './index.less';

const imageRegExp = 'http://img.tvb.com';

const ImageCard = ({ imageUrl, summary, freeEpisode }) => {
  const { t } = useTranslation('programme');
  const url = imageUrl ? imageUrl.replace(imageRegExp, 'https://img.tvb.com') : '';
  const tipWords =
    freeEpisode > 0
      ? t(freeEpisode === 1 ? 'freeEpisode' : 'freeEpisodes').replace('{episode}', freeEpisode)
      : '';

  const [imageStatus, setImageStatus] = useState(true);

  return (
    <div className="image-card">
      {imageStatus ? (
        <Image
          className="image-card-image"
          src={url}
          preview={false}
          async
          onError={() => {
            setImageStatus(false);
          }}
        />
      ) : (
        <div className="image-error">
          <span className="image-error-msg">{summary}</span>
        </div>
      )}
      {/* <div className="image-card-summary">
        <span>{summary}</span>
      </div> */}
      {freeEpisode && (
        <div className="image-tips">
          <span>{tipWords}</span>
        </div>
      )}
    </div>
  );
};

export default ImageCard;
