const config = {
  env: 'production',
  version: '20220801',
  gtmId: 'GTM-5L5RNPX',
  i18n: {
    debug: false,
  },
  host: 'https://s6bo.mytvsuper.com',
  api: 'https://s6bo.mytvsuper.com/api',
  ws: 'wss://s6bo.mytvsuper.com/api/ws',
  mytvsuper: {
    baseUrl: 'https://mytvsuper.com',
    web: 'result',
    universal: 'programme',
  },
  programme: {
    baseUrl: 'https://programme.mytvsuper.com',
    universal: 'programme',
  },
  session: {
    baseUrl: 'https://www.mytvsuper.com',
    path: '/api/auth/getSession/chatbot/',
  },
  cs: {
    baseUrl: 'https://cs.mytvsuper.com',
  },
  loginUrl: 'https://www.mytvsuper.com/',
  bossClientToken: 'RTU8aa9d4w35CeTvXkgd3bH2',
  heartTimeInSecond: 10,
};

module.exports = config;
