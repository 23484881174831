import React from 'react';

import ImageCard from '@/components/imageCard';
import { useProfileContext } from '@/hooks/useProfile';
import { programBtnClick } from '@/utils/gtm';
import { getImageUrlToTls } from '@/utils/imageUtil';
import { getProgrammeDeeplink } from '@/utils/deeplink';

import './index.less';

/**
 *
 * @param {*} programme
 *    id: string
 *    title: string
 *    image: Url
 *    start_time: string
 *    end_time: string
 *    description: string
 *
 * @returns
 */
const ProgrammeCard = ({ programme, programmeLabels = {} }) => {
  const {
    programmeId,
    ref,
    title,
    sub_title,
    path,
    image = {},
    programme_name,
    programme_info: programmeInfo = [],
  } = programme;

  const { userProfile, lang, platformInfo } = useProfileContext();

  const redirectLink = getProgrammeDeeplink({
    lang,
    programme: {
      path,
      title: lang === 'tc' ? title?.trim()?.replace(' ', '-') : title?.replace(' ', '-'),
    },
    platform: platformInfo,
  });
  const tips = programmeLabels[programmeId || ref];

  const onClick = () => {
    console.log('program schedule deeplink', redirectLink);

    programBtnClick({
      bossID: userProfile?.boss_id,
      profileID: userProfile?.extra?.profile_id,
      programId: programmeId || ref,
      ID: programme_name?.tc || title,
      label: '追劇時間表',
      redirect: userProfile?.device_os,
      platform: platformInfo.platformType,
    });

    window.open(redirectLink, '_self');
  };

  return (
    <div
      className="programmed-card"
      style={{ backgroundImage: `url(${getImageUrlToTls(image.landscape_small)})` }}
      onClick={onClick}
    >
      <div className="programmed-card-container">
        <ImageCard imageUrl={getImageUrlToTls(image.landscape_small)} tips={tips} lang={lang} />
        <div
          className={`programme-summary ${
            lang === 'en' ? 'en-programme-summary' : 'tc-programme-summary'
          }`}
        >
          <div className="programme-title">{title}</div>
          {lang === 'tc' && sub_title ? (
            <div className="programme-description">{sub_title}</div>
          ) : null}
        </div>
        {programmeInfo?.schedule ? (
          <div className="programme-last">
            <span>{programmeInfo.schedule}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProgrammeCard;
