import React, { useEffect, useState } from 'react';

import './index.scss';

const Fireworks = ({ visible, onFinish = () => {} }) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (visible && !init) {
      window.initFireworks();

      setTimeout(() => {
        onClose();
      }, 1800);
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onClose = () => {
    window.closeFireworks();

    const mask = document.getElementById('fireworks-container');
    mask.style.display = 'none';

    onFinish();
    setInit(false);
  };

  return (
    <>
      <div>
        <div id="fireworks"></div>
      </div>
      <div id="fireworks-container" className="container">
        <div className="stage-container remove">
          <div className="canvas-container">
            <canvas id="trails-canvas"></canvas>
            <canvas id="main-canvas"></canvas>
          </div>
        </div>
      </div>
    </>
  );
};

export default Fireworks;
