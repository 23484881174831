// @flow strict

import * as R from 'ramda';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import cookie from 'react-cookies';

import { getToken } from '@/client/super';
import config from '@/config';
import { sendExceptions } from './utils/exception';

const authContext = createContext({
  authInfo: { accessToken: null, loginStatus: 0, mzToken: null },
  clientToken: null,
});

export const AuthContextProvider = ({ children }) => {
  const [authInfo, setAuthInfo] = useState({ accessToken: null, loginStatus: 0, mzToken: null });
  const clientTokenRef = useRef();

  useEffect(() => {
    const loadUserProfile = async () => {
      let accessToken = cookie.load('Tvb-Boss-User-Token') || config.devToken;
      let mzToken = cookie.load('Tvb-Mz-Token');
      clientTokenRef.current = cookie.load('Tvb-Boss-Client-Token') || config.bossClientToken;

      if (!accessToken) {
        try {
          const result = await getToken();

          accessToken = R.path(['user', 'token'], result);
          mzToken = R.path(['user', 'mz_token'], result);
        } catch (err) {
          console.log('Get session status failed with error', err);

          sendExceptions({
            token: '',
            langPrefix: 'tc',
            errorType: 'info',
            error: 'Get user sessions error' + err.message,
          });

          setAuthInfo({
            accessToken: null,
            loginStatus: 2,
            mzToken: null,
          });
          return;
        }
      }

      if (accessToken) {
        setAuthInfo({
          accessToken,
          loginStatus: 1,
          mzToken,
        });

        console.log('Has logined');
      } else {
        console.log('Get session status failed empty');

        setAuthInfo({
          accessToken: null,
          loginStatus: 2,
          mzToken: null,
        });
      }
    };

    loadUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <authContext.Provider
      value={{
        authInfo: authInfo,
        clientToken: clientTokenRef.current,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export const useAuthContext = () => useContext(authContext);
