import React from 'react';

import './index.less';

const TitleCard = ({ title }) => {
  return title ? (
    <div className="title-wrapper">
      <div>
        <div className="title-before"></div>
        <div className="title-content">{title}</div>
      </div>
    </div>
  ) : null;
};

export default TitleCard;
