import React, { useEffect, useRef } from 'react';

import './index.less';

const PullLoad = ({
  loadMore, // 获取更多
  hasMore = true, // 能否获取更多
  threshold = 20, // 滚动拉去更多的距离(像素)
  initialLoad = false, // 初始化时加载一次
  initTop = 80, // 距顶高度
  children,
}) => {
  const topStart = useRef(0);
  const touchStart = useRef(0);
  const loading = useRef(false);

  useEffect(() => {
    const init = (outerScroller, scrollDoc) => {
      const messageList = document.getElementById('message-list');
      outerScroller.addEventListener(
        'touchstart',
        function (event) {
          const touch = event.targetTouches[0]; // 把元素放在手指所在的位置
          touchStart.current = touch.pageY;
          topStart.current = scrollDoc.offsetTop;
        },
        false,
      );

      outerScroller.addEventListener(
        'touchmove',
        function (event) {
          const touch = event.targetTouches[0];

          const topValue = scrollDoc.offsetTop + touch.pageY - touchStart.current;
          const top = topValue >= 0 ? topValue : 0;
          if (
            (scrollDoc.offsetTop > 0 ||
              messageList.getBoundingClientRect().top + threshold >= scrollDoc.offsetTop) &&
            top >= 0 &&
            top < 160
          ) {
            scrollDoc.style.top = topValue + 'px';

            touchStart.current = touch.pageY;
          }
        },
        false,
      );

      outerScroller.addEventListener(
        'touchend',
        function (event) {
          touchStart.current = 0;
          var top = scrollDoc.offsetTop;

          document.body.style.overflow = 'auto';

          if (top >= threshold && !loading.current && hasMore) {
            loading.current = true;
            refresh(() => {
              loading.current = false;
            });
          }

          if (top > 0) {
            scrollDoc.style.top = topStart.current + 'px';
            scrollDoc.style.transform = 'translate(0px, 0px)';
          }
        },
        false,
      );

      function refresh(callback) {
        const getMorePromise = new Promise(function (resolve, reject) {
          setTimeout(() => {
            loadMore()?.then(() => resolve());
            resolve();
          }, 10);
        });

        getMorePromise.then(() => {
          callback();
        });
      }
    };

    const scrollDoc = document.querySelector('.scroll');
    const outerScroller = document.querySelector('.outerScroller');

    if (outerScroller && scrollDoc) {
      init(outerScroller, scrollDoc);
    } else {
      console.log('Missing scroll container..', !!outerScroller, !!scrollDoc);
    }
  });

  return (
    <div id="outerScroller" className="outerScroller">
      <div
        id="scroll"
        className="scroll"
        style={{ ...(initTop || initTop === 0 ? { top: initTop + 'px' } : {}) }}
      >
        <div id="refresh-body" className="refresh-body">
          {' '}
          {children}
        </div>
      </div>
    </div>
  );
};

export default PullLoad;
