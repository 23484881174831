export const customer = {
  account: {
    bossId: 'CID',
    name: 'English name',
    email: 'Email',
    phone: 'Mobile Number',
    tvbAccount: 'TVB member ID',
    tvbAccountEmail: 'TVB member ID Email',
  },
  button: {
    changePassword: 'Change Account Password',
    changeBookingPassword: 'Change Booking Password',
    forgetPassword: 'Forget Account Password',
    forgetBookingPassword: 'Forget Booking Password',
    next: 'Next',
    confirm: 'Confirm',
    close: 'Close',
    cancel: 'Back',
  },
  copied: 'Copied!',
  label: {
    // account password
    account: {
      currentPassword: 'Existing Account Password',
      newPassword: 'New Account Password',
      newConfirmPassword: 'Confirm New Account Password',
      changePassword: 'Change Account Password',
      forgetPassword: 'Forget Account Password',
      passwordFormator: '*The password must be 4 digits',
      passwordSuccess: 'Your account password has been updated successfully.',
      forgetPasswordPrefix: 'Please enter the complete phone number for identity check. ',
      forgetPasswordLabel:
        'If a correct phone number is entered, the new password will be sent to your registered phone number via SMS',
      forgetPasswordSuccess:
        'The new Account password will be sent to your mobile number through SMS',
    },
    // booking password
    booking: {
      currentPassword: 'Existing Booking Password',
      newPassword: 'New Booking Password',
      newConfirmPassword: 'Confirm New Booking Password',
      changePassword: 'Change Booking Password',
      forgetPassword: 'Forget Booking Password',
      passwordFormator: '*The password must be 4 digits',
      passwordSuccess: 'Your booking password has been updated successfully.',
      forgetPasswordPrefix: 'Please enter the complete phone number for identity check.',
      forgetPasswordLabel:
        'If a correct phone number is entered, the new password will be sent to your registered phone number via SMS',
      forgetPasswordSuccess:
        'The new booking password will be sent to your mobile number through SMS Confirm',
    },
    // email account password
    tvbAccount: {
      currentPassword: 'Existing Account Password',
      newPassword: 'New Account Password',
      newConfirmPassword: 'Confirm New Account Password',
      changePassword: 'Change Account Password',
      forgetPassword: 'Forget Account Password',
      passwordFormator:
        '*The password must consist of a minimum of 6 characters, either English letters or numbers.',
      passwordSuccess: 'Your account password has been updated successfully.',
      forgetPasswordPrefix: 'Please enter the complete email for identity check. ',
      forgetPasswordLabel:
        'If a correct email is entered, the reset password email will be sent to your inbox.',
      forgetPasswordInput: 'TVB member ID Email',
      forgetPasswordSuccess:
        'The new account password will be sent to your mobile number through SMS',
    },
    error: {
      passwordIncorrect: 'Existing password incorrect, please enter again.',
      fourPasswordIncorrect: 'Password must be a 4 digits number, please enter again.',
      confirmPasswordIncorrect: "Those passwords didn't match. Try again.",
      limitFiveError: 'The quota of password attempts is exceed, ',
      contactLabel: 'please call customer service hotline ',
      contactPhone: '2399-9666',
      contactLabelLater: ' for assistance',
      newPasswordDuplicated: 'Do not use the same password as before, please enter again.',
      phoneIncorrect: 'Invalid mobile number, please check and input again',
      sixPasswordIncorrect:
        'The password must consist of a minimum of 6 characters, either English letters or numbers, please enter again.',
      emailIncorrect: 'Invalid email, please check and input again',
    },
  },
};
