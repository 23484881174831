const WeatherType = {
  sunny: 'sunny',
  clouds: 'clouds',
  rain: 'rain',
  lightning: 'lightning',
  wind: 'wind',
};

export const DEFAULT_WEATHER = WeatherType.clouds;

export default WeatherType;
