import { useEffect, useState } from 'react';

import { useProfileContext } from '@/hooks/useProfile';
import TextModule from '@/components/textModule';
import SwiperContainer from '@/components/swiperContainer';
// import VideoCard from '@/components/videoCard';
import IconCard from '@/components/iconCard';
import WaitingCard from '@/components/waitingCard';
import { getDeeplinkFromAppVersion } from '@/utils/deeplink';

import './index.less';

const Introduction = ({ data = {}, onFunctionClick, scrollTop }) => {
  const { platformInfo } = useProfileContext();
  const { introduction } = data;

  // TODO: phase 2.0 remove functions in greeting
  // const { introduction, functions: functionList } = data;
  const functionList = [];
  const functions = functionList.reduce(
    (sum, item) =>
      // TODO: check other clients
      (platformInfo.isApp && item.is_app_client === 1) || !platformInfo.isApp
        ? [...sum, item]
        : sum,
    [],
  );

  // const videos = introduction.video_desc || [];

  const [greeting, setGreeting] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (greeting) {
        setGreeting(false);

        scrollTop && scrollTop();
      }
    }, 3000);
  });

  return (
    <div className="introduction-card">
      <TextModule words={introduction.text_desc} audio={introduction.audio_desc} />
      {/* <SwiperContainer
        slideClassName={'video-swiper-slide-item'}
        items={videos.map((item, index) => (
          <VideoCard
            key={index}
            className={'video-card-view'}
            videoUrl={item?.video_info?.url}
            poster={item?.thumb_info?.url}
            summary={item.description}
            size="normal"
            playsinline={true}
            silkyPlay={true}
          />
        ))}
      /> */}
      <SwiperContainer
        slideClassName={'function-swiper-slide-item'}
        items={functions.map((item, index) => {
          const href = platformInfo.isApp
            ? getDeeplinkFromAppVersion({ deeplink: item.deep_link, platform: platformInfo })
            : item.website_url;
          return (
            <IconCard
              key={index}
              imageUrl={item?.icon_url}
              summary={item.title}
              {...(item.is_keyword === 1
                ? { onClick: () => onFunctionClick(item.title) }
                : { href })}
            />
          );
        })}
      />
      {(introduction.text_greet && greeting) ? (
        <WaitingCard className={`${greeting ? '' : 'no-waiting'}`} />
      ) : (
        <TextModule words={introduction.text_greet} audio={introduction.audio_greet} />
      )}
    </div>
  );
};

export default Introduction;
