import { useEffect, useState } from 'react';
import { Modal } from 'antd';

import { useProfileContext } from '@/hooks/useProfile';
import SwiperContainer from '@/components/swiperContainer';
import ProgrammeListCard from '@/components/programmeListCard';
import ProgrammeItem from '@/components/programmeListCard/programmeItem';
import { ReactComponent as DoorCloseIcon } from '@/assets/svg/icon_door_arrow.svg';

import './index.less';

const DokodemoDoor = ({ data = [] }) => {
  const { lang } = useProfileContext();
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [group, setGroup] = useState({});
  const [hidden, setHidden] = useState(false);
  const [bodyBackground, setBodyBackground] = useState('#1e1e1e');
  const [currentOpenIndex, setCurrentOpenIndex] = useState(-1);

  const onMore = (detail) => {
    setGroup(detail);
    setShowMoreModal(true);
    setBodyBackground('#1e1e1e');
    setTimeout(() => {
      setBodyBackground('unset');
    }, 600);
  };

  const onMoreClose = () => {
    setHidden(true);
    setBodyBackground('#1e1e1e');

    setTimeout(() => {
      setGroup({});
      setShowMoreModal(false);
      setHidden(false);
      setCurrentOpenIndex(-1);
    }, 300);
  };
  useEffect(() => {
    const afterOpenMoreChange = (open) => {
      if (open) {
        // 一进来定位到第4个卡片，往上滑能看到前3个
        const detailTarget = document.querySelector('.progrmame-list-modal-body');
        const index = currentOpenIndex === -1 ? 3 : currentOpenIndex;
        detailTarget.scrollBy({ left: 0, top: 120 * index });
      }
    };

    setTimeout(() => {
      afterOpenMoreChange(showMoreModal);
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMoreModal]);

  const handleMoreTags = (index, item) => {
    setCurrentOpenIndex(index);
    onMore(item);
  };

  return (
    <div className="dokodemo-door">
      <SwiperContainer
        slideClassName={'dokodemo-swiper-slide-item'}
        items={data.map((item, index) => {
          return (
            <ProgrammeListCard
              key={index}
              programmeList={item}
              showMoreProgrmme={onMore}
              handleMoreTags={(index) => handleMoreTags(index, item)}
              showAllTags={false}
            />
          );
        })}
      />
      <Modal
        visible={showMoreModal}
        centered
        width={'100%'}
        height={'100%'}
        closable={false}
        getContainer={() => document.getElementById('root')}
        wrapClassName="more-preogramme-modal"
        maskClosable={false}
        mask={false}
        footer={null}
        destroyOnClose
        transitionName=""
        maskTransitionName=""
        style={{ animation: hidden ? 'tvb-up-down 300ms' : '' }}
      >
        <div className="progrmame-list-modal-header">
          <span>{group[lang === 'en' ? 'title_en' : 'title_tc'] || '标题'}</span>
        </div>
        <div className="progrmame-list-modal-background"></div>
        <div
          className="progrmame-list-modal-body"
          style={{ '--body-background-color': bodyBackground }}
        >
          <div className="progrmame-list-modal-list">
            {group.items?.map((programme, index) => (
              <ProgrammeItem
                id={`more_list_id_${index}`}
                key={index}
                programme={programme}
                showAllTags={index === currentOpenIndex}
              />
            ))}
          </div>
          <div className="progrmame-list-modal-close">
            <div className="door-close-icon-container" onClick={onMoreClose}>
              <DoorCloseIcon className="door-close-icon" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DokodemoDoor;
