export const contact = {
  email: 'Email',
  phone: 'Service Hotline',
  whatsApp: 'WhatsApp',
  contactConfirm: 'Confirm to chat with or leave message to our customer service officer online?',
  callConfirm: 'Call the Customer Service Hotline?',
  cancelText: 'Cancel',
  confirmText: 'OK',
  callText: 'Call',
  copied: 'Copied!',
};
