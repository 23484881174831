import React from 'react';

import VideoPlayer from '@/components/videoPlayer';

import './index.less';

const VideoCard = ({
  className,
  videoUrl,
  poster,
  summary,
  size = 'small',
  playsinline = true,
  pauseReload = false,
  silkyPlay = false,
}) => {
  return (
    <div className={`${className} video-card ${size}-video-card`}>
      <VideoPlayer
        className="video-card-video"
        url={videoUrl}
        poster={poster}
        playsinline={playsinline}
        pauseReload={pauseReload}
        silkyPlay={silkyPlay}
      />
      <div className={`${size}-video-card-summary video-card-summary`}>
        <span>{summary}</span>
      </div>
    </div>
  );
};

export default VideoCard;
