// @flow strict

const R = require('ramda');

const devConfig = require('./env.development');
const localConfig = require('./env.local');
const prodConfig = require('./env.production');
const tvbConfig = require('./env.tvb');
const qaConfig = require('./env.qa');

const defaultConfig = require('./env.default');

const getEnvConfig = (configSet) => {
  switch (configSet) {
    case 'development':
      return devConfig;
    case 'qa':
      return qaConfig;
    case 'production':
      return prodConfig;
    case 'tvb':
      // 内网环境
      return tvbConfig;
    default:
      return localConfig;
  }
};

const withDefaultConfig = (config) => R.mergeDeepRight(defaultConfig, config);

const result = R.compose(withDefaultConfig, getEnvConfig)(process.env.CONFIG_SET);

module.exports = result;
