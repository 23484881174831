import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { List } from 'antd';

import ButtonCard from '@/components/buttonCard';
import { useProfileContext } from '@/hooks/useProfile';

import './index.less';

const MoreModule = ({ currentId, records = [] }) => {
  const modules = records.map((item, index) => (
    <List.Item className="more-module" key={index}>
      {item.map((record, index) => (
        <div key={index}>{record?.template}</div>
      ))}
    </List.Item>
  ));

  const [data, setData] = useState([modules[0]]);
  const [list, setList] = useState([modules[0]]);

  const { lang } = useProfileContext();

  // TODO: t('showMore') in i18n
  const showMore = lang === 'tc' ? '顯示更多' : 'Show More';

  const onClick = () => {
    const newData = list.concat([modules[list.length]]);
    setData(newData);
    setList(newData);

    // 点击 查看更多/显示更多 后滚动到最底部
    setTimeout(() => {
      const current = document.getElementById(currentId);
      if (current) {
        current.scrollIntoView({ behavior: 'auto', block: 'end' });

        const chatBody = document.getElementById('chat-body');
        if (chatBody) {
          chatBody.scrollBy(0, 60);
        }
      }
    }, 0);
  };

  const loadMore =
    data.length < modules.length ? (
      <div key={nanoid()} className="more-module-button fade-in-item">
        <ButtonCard label={showMore} onClick={onClick} />
      </div>
    ) : null;

  return (
    <div id={currentId}>
      <List
        className="more-module-list"
        loading={false}
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={data}
        bordered={false}
        renderItem={(item) => item}
      />
    </div>
  );
};

export default MoreModule;
