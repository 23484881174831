import React, { useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.less';

import { ReactComponent as RefreshIcon } from '@/assets/svg/icon_refresh.svg';

const ConnectFailed = ({ redirectTo }) => {
  const { t } = useTranslation('common');
  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = () => {
    const href = redirectTo || window.location.href;
    setRefreshing(true);

    setTimeout(() => {
      window.location.href = href;
      setRefreshing(false);
    }, 300);
  };

  return (
    <div id="connecting-failed" className="connecting-failed">
      <div className="conntect-content">
        <div className="failed-sumary">
          <span>{t('notConnect')}</span>
        </div>
        <Button className="refresh-button" onClick={onRefresh}>
          <span className={`refresh-icon ${refreshing ? 'refreshing' : ''}`}>
            <RefreshIcon />
          </span>
          <span>{t('refresh')}</span>
        </Button>
      </div>
    </div>
  );
};

export default ConnectFailed;
