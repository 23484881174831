import React from 'react';
import { useTranslation } from 'react-i18next';

import { getTimeInNeed } from '@/utils/dateTimeUtil';

import './index.less';

const TimeShower = ({ time, last }) => {
  const { t } = useTranslation('common');
  const timeLocal = getTimeInNeed(time, { last, t });

  return timeLocal ? <p className="time-shower">{timeLocal}</p> : null;
};

export default TimeShower;
