import React from 'react';
import { Button } from 'antd';

import { ReactComponent as ArrayIcon } from '@/assets/svg/icon_arrow.svg';
import './index.less';

const ButtonCard = ({ label = '查看更多', url = '/', onClick }) => {
  const onClickHandler = () => {
    console.log('button deeplink url:', url);
    window.open(url, '_self');
  };

  return (
    <Button className="button-card" onClick={onClick || onClickHandler}>
      <span>
        {label}

        <ArrayIcon className={onClick ? 'down-outlined-arrow' : 'right-outlined-arrow'} />
      </span>
    </Button>
  );
};

export default ButtonCard;
