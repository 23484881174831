import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, message, Image, Input } from 'antd';
import base64 from 'base-64';

import { useAuthContext } from '@/hooks/useAuth';
import { useProfileContext } from '@/hooks/useProfile';
import { changePassword, changeEmailPassword } from '@/client/chabot';
import { ReactComponent as IconHidePwdSvg } from '@/assets/svg/icon_pwd_hide.svg';
import { ReactComponent as IconShowPwdSvg } from '@/assets/svg/icon_pwd_show.svg';
import { ReactComponent as TipsIcon } from '@/assets/svg/icon_tips.svg';
import PopupLoadingImage from '@/assets/png/icon_popup_loading@2x.png';
import './index.less';

// type= account,tvbAccount,booking
const ChangePasswordForm = ({
  type = 'account',
  customer,
  onForgetPassword,
  onFinish = () => {},
}) => {
  const { t } = useTranslation('customer');
  const { t: tCommon } = useTranslation('common');
  const { authInfo = {}, clientToken } = useAuthContext();
  const { lang } = useProfileContext();

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [loading, setLoading] = useState(false);
  const [errorLabels, setErrorLabels] = useState([]);
  const changePIN = type === 'tvbAccount' ? changeEmailPassword : changePassword;

  useEffect(() => {
    if (values && form.isFieldsTouched(Object.keys(values || {}), true)) {
      form.validateFields(Object.keys(values || {}), { validateOnly: true }).then(
        () => {
          if (!values['oldPassword']) {
            setSubmitDisabled(true);
          } else {
            setSubmitDisabled(false);
          }
        },
        () => {
          setSubmitDisabled(true);
        },
      );
    }

    if (values && form.isFieldTouched('oldPassword')) {
      setErrorLabels([]);
    }
    if (values && form.isFieldTouched('newPassword')) {
      setErrorLabels([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const submit = async () => {
    setLoading(true);
    const { accessToken: token, mzToken } = authInfo;
    form.validateFields(Object.keys(values || {}), { validateOnly: true }).then(
      async () => {
        setSubmitDisabled(false);

        let result = '';
        const data = {
          old_password: base64.encode(values.oldPassword),
          new_password: base64.encode(values.newPassword),
        };
        try {
          const res = await changePIN({ lang, token, clientToken, mzToken, data });
          console.log('changePIN result', res);
          if (res.code === 0) {
            if (res.data.success) {
              result = 'success';
            } else {
              // 旧密码错误
              let error = false;
              if (['20010202', '20013401', '20010205'].includes(res.data.errors.code)) {
                setErrorLabels([
                  {
                    validateStatus: 'error',
                    help: t('label.error.passwordIncorrect'),
                  },
                ]);
                error = true;
              }
              // 账号锁定wrong password 5 times.
              if (res.data.errors.code === '20000013') {
                result = 'tip';
                error = true;
              }

              // 新密码不可用
              if (['20010201', '20010203	', '20010204'].includes(res.data.errors.code)) {
                setErrorLabels([
                  null,
                  {
                    validateStatus: 'error',
                    help: t('label.error.passwordIncorrect'),
                  },
                ]);
                error = true;
              }

              // 新旧密碼一致錯誤
              if (['20010206'].includes(res.data.errors.code)) {
                setErrorLabels([
                  null,
                  {
                    validateStatus: 'error',
                    help: t('label.error.newPasswordDuplicated'),
                  },
                ]);
                error = true;
              }

              if (!error) {
                result = 'error';
              }
            }
          } else {
            result = 'error';
          }
        } catch (err) {
          result = 'error';
        }

        setTimeout(() => {
          setLoading(false);
          if (!result) {
            return;
          }
          // 错误弹窗
          if (result === 'error') {
            return message.error({
              icon: <TipsIcon className="tips-icon" />,
              className: 'toast-tips-content',
              getContainer: () => document.getElementById('root'),
              content: tCommon('network.error'),
              duration: 2,
            });
          } else {
            onFinish(result || 'success', type);
          }
        }, 500);
      },
      () => {
        setSubmitDisabled(true);
      },
    );
  };

  return (
    <div className="change-password-form">
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        initialValues={{}}
        onFinish={submit}
        autoComplete="off"
      >
        <div className={`pwd-modal-title ${type} ${lang}`}>
          {(type !== 'account' ? `${t('account.tvbAccount')}${lang === 'en' ? '\n' : ' - '}` : '') +
            t(`label.${type}.changePassword`)}
        </div>
        <Form.Item
          name="oldPassword"
          validateFirst={true}
          validateTrigger={['onBlur']}
          rules={[
            {
              required: false,
              min: 1,
              message: t(
                `label.error.${
                  type === 'tvbAccount' ? 'sixPasswordIncorrect' : 'fourPasswordIncorrect'
                }`,
              ),
            },
          ]}
          getValueFromEvent={(event) => {
            // 去掉空格
            return event.target.value.replace(/\s+/g, '');
          }}
          {...(errorLabels[0] || {})}
        >
          <Input.Password
            autoComplete={'off'}
            maxLength={20}
            pattern="\S*"
            placeholder={t(`label.${type}.currentPassword`)}
            iconRender={(visible) => (visible ? <IconHidePwdSvg /> : <IconShowPwdSvg />)}
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          validateFirst={true}
          validateTrigger={['onBlur']}
          rules={[
            {
              required: true,
              max: type === 'tvbAccount' ? 20 : 4,
              min: type === 'tvbAccount' ? 6 : 4,
              pattern: type === 'tvbAccount' ? /^[0-9a-zA-Z]{6,20}$/ : /^\d{4}$/,
              message: t(
                `label.error.${
                  type === 'tvbAccount' ? 'sixPasswordIncorrect' : 'fourPasswordIncorrect'
                }`,
              ),
            },
          ]}
          getValueFromEvent={(event) => {
            // 去掉空格
            return event.target.value.replace(/\s+/g, '');
          }}
          {...(errorLabels[1] || {})}
        >
          <Input.Password
            autoComplete={'off'}
            minLength={type === 'tvbAccount' ? 6 : 4}
            maxLength={type === 'tvbAccount' ? 20 : 4}
            placeholder={t(`label.${type}.newPassword`)}
            iconRender={(visible) => (visible ? <IconHidePwdSvg /> : <IconShowPwdSvg />)}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          validateTrigger={['onBlur']}
          validateFirst={true}
          rules={[
            {
              required: true,
              max: type === 'tvbAccount' ? 20 : 4,
              min: type === 'tvbAccount' ? 6 : 4,
              pattern: type === 'tvbAccount' ? /^[0-9a-zA-Z]{6,20}$/ : /^\d{4}$/,
              message: t(
                `label.error.${
                  type === 'tvbAccount' ? 'sixPasswordIncorrect' : 'fourPasswordIncorrect'
                }`,
              ),
            },
            ({ getFieldValue }) => ({
              validator: (_, value) => {
                if (!!value && value !== getFieldValue('newPassword')) {
                  return Promise.reject(new Error(t('label.error.confirmPasswordIncorrect')));
                }
                return Promise.resolve();
              },
            }),
          ]}
          getValueFromEvent={(event) => {
            // 去掉空格
            return event.target.value.replace(/\s+/g, '');
          }}
          {...(errorLabels[2] || {})}
        >
          <Input.Password
            autoComplete={'off'}
            minLength={type === 'tvbAccount' ? 6 : 4}
            maxLength={type === 'tvbAccount' ? 20 : 4}
            placeholder={t(`label.${type}.newConfirmPassword`)}
            iconRender={(visible) => (visible ? <IconHidePwdSvg /> : <IconShowPwdSvg />)}
          />
        </Form.Item>
        <div className="pwd-format-label">{t(`label.${type}.passwordFormator`)}</div>
        <Form.Item className="pwd-submit-btn-item">
          <Button
            className={`pwd-submit-btn ${submitDisabled ? 'pwd-submit-btn-disbaled' : ''}`}
            disabled={submitDisabled}
            type="primary"
            htmlType="submit"
          >
            {loading ? (
              <Image className="loading-icon" src={PopupLoadingImage} preview={false} async />
            ) : (
              t('button.confirm')
            )}
          </Button>
        </Form.Item>
        <div className="pwd-format-label pwd-forget-btn-label">
          <Button type="link" className="pwd-forget-btn" onClick={onForgetPassword}>
            {t(`button.${type === 'booking' ? 'forgetBookingPassword' : 'forgetPassword'}`)}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ChangePasswordForm;
