import React from 'react';
import { useTranslation } from 'react-i18next';

import { getDayOrNight } from '@/utils/dateTimeUtil';
import { DEFAULT_WEATHER } from '@/constants/weather';

import { ReactComponent as CloudsIcon } from '@/assets/svg/icon_clouds.svg';
import { ReactComponent as LightningIcon } from '@/assets/svg/icon_lightning.svg';
import { ReactComponent as RainIcon } from '@/assets/svg/icon_rain.svg';
import { ReactComponent as SunnyMoonIcon } from '@/assets/svg/icon_sunny_moon.svg';
import { ReactComponent as SunnyIcon } from '@/assets/svg/icon_sunny.svg';
import { ReactComponent as WindIcon } from '@/assets/svg/icon_wind.svg';

import './index.less';

const WeatherIcon = ({ weather, className, onClick }) => {
  const time = getDayOrNight();
  const { t } = useTranslation('weather');

  const SmallIcon = ({ className }) => {
    const iconMap = {
      clouds: <CloudsIcon className={className} />,
      lightning: <LightningIcon className={className} />,
      rain: <RainIcon className={className} />,
      sunnyMoon: <SunnyMoonIcon className={className} />,
      sunny: <SunnyIcon className={className} />,
      wind: <WindIcon className={className} />,
    };

    return time === 'night' && weather === 'sunny'
      ? iconMap.sunnyMoon
      : iconMap[weather] || iconMap[DEFAULT_WEATHER];
  };

  const getImageClass = () => {
    const classMap = {
      clouds: 'clouds-icon',
      lightning: 'lightning-icon',
      rain: 'rain-icon',
      sunny: 'sunny-icon',
      wind: 'wind-icon',
      sunnyMoon: 'sunny-moon-icon',
    };
    return time === 'night' && weather === 'sunny' ? classMap.sunnyMoon : classMap[weather];
  };

  return (
    <div
      className={`weather-icon-container ${className ? className : ''} ${getImageClass()}`}
      onClick={onClick}
    >
      <div>
        <SmallIcon className="weather-icon-img" />
        <span className="weather-icon-content">{t(weather)}</span>
      </div>
    </div>
  );
};

export default WeatherIcon;
