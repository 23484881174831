import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { getBaseInfo } from '@/client/chabot';
import { useAuthContext } from '@/hooks/useAuth';
import { getPlatformInfo } from '@/utils/platform';
import { getLang, simplifyLang } from '@/utils/languageUtil';
import { baseTracking } from '@/utils/gtm';
import { sendExceptions } from './utils/exception';

const profileContext = createContext({
  userProfile: null,
  baseInfo: null,
  platformInfo: {},
  lang: 'tc',
});

export const ProfileContextProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState();
  const [baseInfo, setBaseInfo] = useState();
  const platformInfo = useRef(getPlatformInfo());

  const { authInfo = {}, clientToken } = useAuthContext();
  const { accessToken: token, mzToken } = authInfo;

  useEffect(() => {
    const getInfo = async () => {
      const info = await getBaseInfo({
        lang: simplifyLang(getLang()),
        clientToken,
        token,
        mzToken,
      });
      if (info?.data) {
        const {
          user_info,
          customer,
          function: functions,
          badge_info: badgeInfo = [],
          weather,
          introduction,
        } = info.data;
        setUserProfile({ ...user_info, customer });
        setBaseInfo({ introduction, functions, weather, badgeInfo });

        baseTracking({
          bossID: user_info?.boss_id,
          profileID: user_info?.extra?.profile_id,
          redirect: platformInfo.current?.os?.name,
          lang: getLang(),
          app_version: platformInfo.current?.appVersion,
          sys_name: platformInfo.current?.browser?.name,
          sys_version: platformInfo.current?.browser?.version?.split('.')[0],
          deviceType: ['iOS', 'Android'].includes(platformInfo.current?.os?.name) ? 'mobile' : 'pc',
          entry: 'others',
        });
      }
    };

    if (token && !baseInfo) {
      getInfo().catch((error) => {
        sendExceptions({
          token: '',
          langPrefix: 'tc',
          errorType: 'warning',
          error: 'Get system info error' + error.message,
        });

        setBaseInfo({ error: true });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <profileContext.Provider
      value={{
        userProfile,
        baseInfo,
        platformInfo: platformInfo.current,
        lang: simplifyLang(getLang()),
      }}
    >
      {children}
    </profileContext.Provider>
  );
};

export const useProfileContext = () => useContext(profileContext);
