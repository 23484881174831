import { sendException } from '@/client/chabot';
import { getPlatformInfo } from '@/utils/platform';

export const sendExceptions = async ({ userProfile = {}, token, langPrefix, error, errorType }) => {
  // 開發環境不發送錯誤報警
  if (!process.env.CONFIG_SET || ['local', 'dev', 'development'].includes(process.env.CONFIG_SET)) {
    return;
  }

  try {
    const platform = getPlatformInfo();
    const appInfo = platform.isApp ? `myTVSUPER;${platform.appVersion}` : '';
    const { os, browser, device } = platform;
    const { boss_id: bossId, extra } = userProfile;

    await sendException({
      token,
      lang: langPrefix === 'tc' ? 'tc' : 'en',
      errorType,
      errorInfo: {
        userInfo: { bossId, profileId: extra?.profile_id },
        appInfo,
        deviceInfo: JSON.stringify({ os, browser, device }),
        error,
      },
    });
  } catch (e) {
    console.error('Log error failed', e);
  }
};
