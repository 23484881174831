import axios from 'axios';
import axiosRetry from 'axios-retry';
import * as R from 'ramda';

const initAxios = ({ baseURL, withCredentials = false }) => {
  const LocalAxios = axios.create({
    baseURL: baseURL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 10000,
    withCredentials,
  });

  LocalAxios.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      console.log('api request appears error:', error.message);

      throw error;
    },
  );

  LocalAxios.interceptors.response.use(
    (response) => {
      const res = R.propOr({}, 'data', response);
      if (!R.has('code', res) || res?.code === 0) {
        return res;
      }
      throw new Error(JSON.stringify(res));
    },
    (error) => {
      if (error.response) {
        const { config, status } = error.response;
        console.log(
          'api response error:',
          `url: ${config.url},`,
          `status:${status},`,
          'error:',
          error.message,
        );
      } else {
        console.log('api response appears error', error.message);
      }

      throw error;
    },
  );

  return LocalAxios;
};

const getClient = ({ baseURL, withCredentials = false }) => {
  const singleAxios = initAxios({ baseURL, withCredentials });

  const retryAxios = initAxios({ baseURL, withCredentials });
  axiosRetry(retryAxios, {
    retries: 3,
    shouldResetTimeout: true,
    retryCondition: (error) => {
      return (
        axiosRetry.isNetworkOrIdempotentRequestError(error) || error?.message?.includes('timeout')
      );
    },
  });

  return { retryAxios, singleAxios };
};

export default getClient;
