import React from 'react';

import Coin from './coin';
import './index.less';

// day,night,sunrise,sunset
const MessageHeader = ({
  id,
  className = '',
  headerInfo,
  weather,
  time,
  onGooeyClick = () => {},
}) => {
  return (
    <div className={className} {...(id && { id })}>
      <div id="message-header" className="message-header">
        <svg className="svg-pop" xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="gooey">
              <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
              <feColorMatrix
                in="blur"
                type="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                result="highContrastGraphic"
              />
              <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
            </filter>
          </defs>
        </svg>
        <div className="weather-content">
          <div className="gooey">
            <span className="gooey-title" onClick={() => onGooeyClick(headerInfo?.keyword, true)}>
              {headerInfo?.title}
            </span>
            <span className="bubbles">
              <span className="bubble"></span>
              <span className="bubble"></span>
            </span>
          </div>
        </div>
        <div className="weather-logo">
          <Coin src={headerInfo?.icon} weather={weather} time={time} />
        </div>
        <div className="weather-logo-bottom"></div>
      </div>
    </div>
  );
};

export default MessageHeader;
